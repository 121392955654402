import { Form, Button, Container } from 'react-bootstrap';
import { useState } from 'react';
import { vendedorInsert } from '../../../services/vendedor';
import Swal from "sweetalert2";

import UserCombo from "./UserCombo"

const FormVentas = () => {
    const [formData, setFormData] = useState({
        first_Name: "",
        last_Name:"",
        email: "",
        phone: "",
        u_Type: "2",
        password: "",
        confirmPassword: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);

        if (formData.first_Name==="" || formData.last_Name==="" || formData.email==="" || formData.password==="" || formData.confirmPassword===""){
            console.log("Hola");
            Swal.fire({
                title:"Error",
                text:"Debes de llenar todos los campos",
                icon:"error"
            });
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            Swal.fire({
                title:"Error",
                text:"Las contraseñas no se parecen",
                icon:"error"
            });
            return;
        }

        try {
            const vendedorResponse = await vendedorInsert(formData);
            if (vendedorResponse.status === 200) {
                Swal.fire({
                    title: "¡Éxito!",
                    text: "Vendedor añadido exitosamente",
                    icon: "success",
                })

            } else {
                Swal.fire({
                    title: "¡Error!",
                    text: vendedorResponse.message,
                    icon: "error",
                });
                return;
            }
            // console.log(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <Container>
            <h4> Añadir personal </h4>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicFirstName">
                    <Form.Label>Nombre(s)</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Entra nombre"
                        name="first_Name"
                        value={formData.first_Name}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicLastName">
                    <Form.Label>Apellidos</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Introducir apellidos"
                        name="last_Name"
                        value={formData.last_Name}
                        onChange={handleChange}
                    />
                </Form.Group>
                
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Correo electrónico</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Introducir correo electrónico"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPhone">
                    <Form.Label>Teléfono</Form.Label>
                    <Form.Control
                        type="tel"
                        placeholder="Introducir teléfono"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicUser">
                    <Form.Label>Tipo de usuario</Form.Label>
                    <Form.Select
                        name="u_Type"
                        onChange={handleChange}
                        value={formData.u_type}
                        defaultValue="2"
                        >
                        <option value="2">Vendedor</option>
                        <option value="3">Operaciones</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Contraseña"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                    <Form.Label>Confirmar contraseña</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Confirmar contraseña"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Button variant="primary" type="submit">
                    Añadir
                </Button>
            </Form>
        </Container>
    );
}

export default FormVentas;