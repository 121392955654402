import { API_URL, getHeaders } from "./config";

export async function productosInsert(data) {
  const response = await fetch(API_URL + `/basic-productos/addProducto`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return await response.json();
}

export async function productosGet({ name, description }) {
  const params = new URLSearchParams({ name, description });
  const url = `${API_URL}/basic-productos/getProducto?${params}`;

  const response = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

  return await response.json();
}

export async function productosGetCombo({ name, description, id }) {
  const params = new URLSearchParams({ name, description, id });
  const url = `${API_URL}/basic-productos/getProductoCombo?${params}`;

  const response = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

  return await response.json();
}

export async function ClienteProductosGetAll() {
  const url = `${API_URL}/libre/getAllProductos`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });

  return await response.json();
}

export async function ClienteProductosGetByCategoria(id) {
  const url = `${API_URL}/libre/ClienteProductosGetByCategoria/${id}`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });

  return await response.json();
}

export async function ClienteCategoriasGetAll() {
  const url = `${API_URL}/libre/ClienteCategoriasGetAll`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}

export async function hotsalesAll() {
  const url = `${API_URL}/libre/hotsales`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}

export async function ClienteProductosGet() {
  const url = `${API_URL}/users/carrito/getProductosCarrito`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });

  return await response.json();
}

export async function ClienteSingleProductoGet({ id }) {
  const url = `${API_URL}/productos/getProducto/${id}`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders,
  });

  return await response.json();
}
