import React, { useEffect, useState } from 'react';
import './leaflet.css';
import {
    MapContainer, TileLayer,
    Marker,Popup
} from 'react-leaflet';
import { Typography } from '@mui/material';

export const OfficeMapLoc = ({heightCom}) => {

    const position = [20.69185593058251, -103.29242311423067]

    const [mapHeight, setMapHeight] = useState(heightCom);

    useEffect(() => {
        setMapHeight(heightCom);
    }, [heightCom])
    

    return(
        <div style={{backgroundColor:"rgba(255,255,255,0)", height: mapHeight+"rem", overflow: "hidden", borderRadius:"15px"}}>
            <MapContainer center={position} zoom={22} scrollWheelZoom={false}>
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}>
                <Popup keepInView={true}>
                    <Typography>
                        <h2>Visitanos</h2>
                        1478 Av Joaquin amaro <br/>
                        Col Oblatos<br/>
                        Jalisco, GDL, México
                        <br/><br/>
                        Disponibilidad:<br/>
                        Lunes: 8:00-18:00 <br/>
                        Martes: 8:00-18:00 <br/>
                        Miercoles: 8:00-18:00 <br/>
                        Jueves: 8:00-18:00 <br/>
                        Viernes: 8:00-18:00 <br/>
                    </Typography>
                </Popup>
                </Marker>
            </MapContainer>
        </div>
    )
}