import { LinearProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import "../../Styles/LoginStyle.css";
import { createUser } from "../../services/LoginService";

const Formulario = (props) => {
  const [inputs, setInputs] = useState({});
  const [passReq, setPassReq] = useState({
    "errorMessage":[],
    "securityLevelPass":0,
    "stateRegButton":true,
    "colorBar":"rgb(255,0,0)",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));

    if (name==="password"){
      let loginVals = {
        "errorMessage":[],
        "securityLevelPass":0,
        "stateRegButton":true,
        "colorBar":"rgb(255,0,0)",
      }

      if (value.length < 8) {
        loginVals.errorMessage.push("Contraseña debe contener al menos 8 caracteres")
      } if (value.search(/[a-z]/) < 0) {
        loginVals.errorMessage.push("Contraseña debe contener al menos una minuscula");
      } if (value.search(/[A-Z]/) < 0) {
        loginVals.errorMessage.push("Contraseña debe contener al menos una mayuscula");
      } if (value.search(/[0-9]/) < 0) {
        loginVals.errorMessage.push("Contraseña debe de contener al menos un numero");
      } if (value.search(/[^a-zA-Z\d]/) < 0){ 
        loginVals.errorMessage.push("Contraseña debe de contener al menos un caracter especial");
      }
      if (loginVals.errorMessage.length>0){
        loginVals.stateRegButton=true
      } else {
        loginVals.stateRegButton=false
      }

      loginVals.securityLevelPass=5-(loginVals.errorMessage.length);

      loginVals.colorBar=getColor(loginVals.securityLevelPass)

      setPassReq(loginVals);
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    setError,
  } = useForm();

  const onSubmit = async () => {
    let response = await createUser(inputs);
    console.log(response.message);
    if (response.status === 200) {
      Swal.fire({
        title: "¡Éxito!",
        text: (response.message),
        icon: "success",
      }).then(() => {
        props.setModalShow(false)
        // localStorage.setItem("user", JSON.stringify(response.data));
        // navigate("/");
      });
    } else {
      Swal.fire({
        title: "¡Error!",
        text: response.message,
        icon: "error",
      }).then(() => {
        if (response.status === 401) {
          // setError("correo", {
          //   type: "custom",
          //   message: response.errors.message,
          // });
        }
      });
    }
  };

  const onError = (errors) => {
    Swal.fire({
      title: "...Ops",
      text: "Tienes algunos campos sin llenar",
      icon: "error",
    });
  };

  const getColor = (value) => {
    // Función para obtener el color en base al valor (0-5)
    const red = [255, 0, 0];
    const orange = [255, 165, 0];
    const green = [0, 128, 0];
  
    if (value <= 2) {
      const factor = value / 2;
      const color = red.map((channel, index) =>
        Math.round(channel + factor * (orange[index] - channel))
      );
      return `rgb(${color.join(',')})`;
    } else {
      const factor = (value - 2) / 3;
      const color = orange.map((channel, index) =>
        Math.round(channel + factor * (green[index] - channel))
      );
      return `rgb(${color.join(',')})`;
    }
  };

  return (
    <div>
      <Form className="t-align-center" onSubmit={handleSubmit(onSubmit, onError)}>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                name="nombre"
                placeholder="Nombre"
                onChange={handleChange}
                required
                autoFocus
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                name="apellido"
                placeholder="Apellido"
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Control
            type="email"
            name="email"
            placeholder="Correo electrónico"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Control
            type="password"
            name="password"
            placeholder="Contraseña"
            onChange={handleChange}
            required
          />
        </Form.Group>

        <LinearProgress 
          variant="determinate" 
          value={(passReq.securityLevelPass)*20} 
          
          sx={{
            height:'10px',
            '& .MuiLinearProgress-bar': {
              backgroundColor: passReq.colorBar,
              backgroundSize: '100% auto', // Aumentar tamaño del gradiente para un efecto suave
              boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)', // Agregar sombra para mejorar legibilidad
              borderRadius: '8px', // Redondear bordes para un aspecto más suave
            },
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)', // Agregar sombra para mejorar legibilidad
            borderRadius: '8px', // Redondear bordes para un aspecto más suave
          }}
        />

        <div style={{margin:"15px 0", textAlign: "left"}}>
          {
            passReq.errorMessage.length>0 ?
            
            (
              <>
                <Typography variant="h6">Requisitos de contraseña </Typography>
                {passReq.errorMessage.map((item) => (
                  <>
                    <Typography variant="h8">
                      {item}
                    </Typography>
                    <br/>
                  </>
                ))}
              </>
            ):
            (<></>)
          }
        </div>

        <Button variant="primary"
          disabled={passReq.stateRegButton} 
          type="submit" 
        >
          Registrarte
        </Button>
      </Form>
    </div>
  );
};

const RegistroForm = (props) => {
  return (
    <>
      <Modal {...props} centered>
        <Modal.Header closeButton>
          <Modal.Title>Registrarte</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formulario setModalShow={props.setModalShow}/>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={() => console.log}>
            Close
          </Button>
          <Button variant="primary">Understood</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RegistroForm;
