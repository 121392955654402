import { useContext, useEffect } from "react";
import { getSocket } from "./socket";
import { AccountContext } from "./hooks/AccountContext";

const useSocketSetup = () => {
  const { user, updateUser } = useContext(AccountContext);

  useEffect(() => {
    const socket = getSocket();

    if (user) {
      // TODO: Perform any necessary actions when user data changes.
    }

    return () => {
      socket.disconnect(); // Disconnect the socket when component unmounts.
    };
  }, [user]);

  useEffect(() => {
    // When the user logs in, reconnect the socket with the new auth details
    if (user) {
      const socket = getSocket();
      socket.auth = {
        userId: user.userid,
        token: user.accessToken,
      };
      socket.connect();
    }
  }, [user]);
};

export default useSocketSetup;
