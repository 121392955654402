import React from "react";
import Producto from "./Producto";
import { Grid } from "@mui/material";

class ListaProductos extends React.Component {
  render() {
    const products = this.props.products;
    return (
      <Grid sx={{ mb: 5 }} container spacing={2}>
        {products.map((product) => (
          <Producto key={product.id} product={product} />
        ))}
      </Grid>
    );
  }
}

export default ListaProductos;
