import React, { useEffect, useRef } from "react";
import { TextInput } from "../textInput/textInput";
import { MessageLeft, MessageRight } from "../chatMessage/chatMessage.js";
import { Paper } from "@mui/material";
import { Wrapper } from "./chatComponentStyle.js";

export default function ChatComponent({
  messages,
  handleNewMessage,
  receiverId,
  conversartionId,
}) {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Wrapper style={{ flex: 2 }}>
      <Paper className="paper">
        <Paper id="style-1" className="messagesBody" onScroll={handleScroll}>
          {messages.map((message) => {
            if (
              parseInt(message.sender_id) ===
              parseInt(localStorage.getItem("userid"))
            ) {
              return (
                <MessageRight
                  key={message.id}
                  message={message.message_text}
                  timestamp={message.formatted_timestamp}
                  displayName={message.sender_name}
                />
              );
            } else {
              return (
                <MessageLeft
                  key={message.id}
                  message={message.message_text}
                  timestamp={message.formatted_timestamp}
                  displayName={message.sender_name}
                />
              );
            }
          })}
          <div ref={messagesEndRef} />
        </Paper>
        <TextInput
          receiverId={receiverId}
          handleNewMessage={handleNewMessage}
          conversartionId={conversartionId}
        />
      </Paper>
    </Wrapper>
  );
}
