import React from "react";
import logo from "../../../assets/images/Logo.png";
import { Link } from "@mui/material";

const Logo = ({ logoHeight }) => {
  const sizeImg = logoHeight != null ? logoHeight : "50";

  return <img src={logo} alt="FortePiel Logo" height={sizeImg} />;
};

export default Logo;
