import React from "react";
import ChartComponent from "./LineGraph";

const Graficas = () => {
  return (
    <div>
      <br></br>
      <h2>Gráficas de productos</h2>
      <br></br>
      <ChartComponent />
      <br></br>
    </div>
  );
};

export default Graficas;
