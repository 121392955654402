import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, Image, Modal, Row, Button } from "react-bootstrap";

const ImageUpload = ({ onChange, name, multiple, ...rest }) => {
  const [images, setImages] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => URL.createObjectURL(file));
    setImages(newImages); // Set the images state to the newly uploaded images only
    onChange({ target: { name: name, value: files } });
  };

  const renderImages = () => {
    return (
      <>
        {images.length > 0 && (
          <Col xs={6} md={4}>
            <Button variant="secondary" onClick={() => setShow(true)}>
              Ver imagenes
            </Button>
          </Col>
        )}
      </>
    );
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  return (
    <Form.Group controlId="formFile" className="mb-3">
      <Form.Label>Seleccionar imagen</Form.Label>
      <Row>
        <Col xs={12} md={8}>
          <Form.Control
            type="file"
            name={name}
            onChange={handleFileChange}
            accept="image/png, image/gif, image/jpeg"
            multiple={multiple}
            {...rest}
          />
        </Col>
        {renderImages()}
      </Row>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Imágenes subidas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {images.map((image, index) => (
              <Col xs={6} md={4} key={index}>
                <Image src={image} thumbnail alt="preview" className="ms-3" />
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Form.Group>
  );
};

ImageUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
};

ImageUpload.defaultProps = {
  multiple: false,
};

export default ImageUpload;
