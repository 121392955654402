import { useContext, useState } from "react";
import { Sidebar as ProSidebar, Menu, MenuItem, useProSidebar, sidebarClasses, menuClasses } from "react-pro-sidebar";
// import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../../pages/theme";

import HomeOutlined from "@mui/icons-material/HomeOutlined";
import PeopleOutlined from "@mui/icons-material/PeopleOutlined";
import ContactsOutlined from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlined from "@mui/icons-material/ReceiptOutlined";
import PersonOutlined from "@mui/icons-material/PersonOutlined";
import DiscountOutlined from "@mui/icons-material/DiscountOutlined";
import CalendarTodayOutlined from "@mui/icons-material/CalendarTodayOutlined";
import InventoryIcon from '@mui/icons-material/Inventory';
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlined from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlined from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlined from "@mui/icons-material/TimelineOutlined";
import MenuOutlined from "@mui/icons-material/MenuOutlined";
import MapOutlined from "@mui/icons-material/MapOutlined";
import { AccountContext } from "../../../hooks/AccountContext";




const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
            }}
            onClick={() => setSelected(title)}
            icon={icon}

            component={<Link to={`.${to}`} />}
        >
            <Typography>{title}</Typography>
        </MenuItem>
    );
};

console.log(menuClasses.button)

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { collapseSidebar, collapsed } = useProSidebar();
    const [selected, setSelected] = useState("Dashboard");

    const { user, setUser } = useContext(AccountContext);

    return (
        // <Box
        //     sx={{
        //         "& .css-dip3t8": {
        //             background: `${colors.primary[400]} !important`,
        //         },
        //         "& .pro-sidebar-inner": {
        //             background: `${colors.primary[400]} !important`,
        //         },
        //         "& .pro-icon-wrapper": {
        //             backgroundColor: "transparent !important",
        //         },
        //         "& .pro-inner-item": {
        //             padding: "5px 35px 5px 20px !important",
        //         },
        //         "& .pro-inner-item:hover": {
        //             color: "#868dfb !important",
        //         },
        //         "& .pro-menu-item.active": {
        //             color: "#6870fa !important",
        //         },
        //     }}
        // >
        <div style={{ display: 'flex' }}>

            <ProSidebar collapsed={collapsed}
                rootStyles={{
                    [`.${sidebarClasses.container}`]: {
                        background: `${colors.primary[400]} !important`,
                    },
                    [`.${menuClasses.button}`]: {
                        padding: "5px 35px 5px 20px !important",
                        "&:hover": {
                            background: `${colors.primary[500]} !important`,
                            color: "#868dfb !important",
                        }
                    },
                    [`.${menuClasses.active}`]: {
                        color: "#6870fa !important",
                    },
                }}
            >
                <Menu iconShape="square">
                    <MenuItem
                        onClick={() => collapseSidebar()}
                        icon={collapsed ? <MenuOutlined /> : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                        }}
                    >
                        {!collapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography variant="h3">
                                    {user.nombre}
                                </Typography>
                                <MenuOutlined />
                            </Box>
                        )}
                    </MenuItem>

                    <Box paddingLeft={collapsed ? undefined : "0%"}>
                        <Item
                            title="Pedidos"
                            to="/"
                            icon={<HomeOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        {/*
                        {!collapsed && (
                            <Typography
                                variant="h6"
                                sx={{ m: "15px 0 5px 20px" }}
                            >
                                Usuarios
                            </Typography>
                        )}
                        <Item
                            title="Agregar ventas"
                            to="/addVentas"
                            icon={<PeopleOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Modificar usuarios"
                            to="/verVentas"
                            icon={<ContactsOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        {!collapsed && (
                            <Typography
                                variant="h6"
                                sx={{ m: "15px 0 5px 20px" }}
                            >
                                Productos
                            </Typography>
                        )}
                        <Item
                            title="Añadir productos"
                            to="/addProductos"
                            icon={<PersonOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Ver productos"
                            to="/verProductos"
                            icon={<InventoryIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        {!collapsed && (
                            <Typography
                                variant="h6"
                                sx={{ m: "15px 0 5px 20px" }}
                            >
                                Categorías
                            </Typography>
                        )}
                        <Item
                            title="Añadir Categorías"
                            to="/addProductosBasicos"
                            icon={<PersonOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Ver Categorías"
                            to="/verProductosBasicos"
                            icon={<CalendarTodayOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        {!collapsed && (
                            <Typography
                                variant="h6"
                                sx={{ m: "15px 0 5px 20px" }}
                            >
                                Gran venta
                            </Typography>
                        )}
                        <Item
                            title="Gran venta"
                            to="/hotsales"
                            icon={<DiscountOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        */}
                    </Box>
                </Menu>
            </ProSidebar>
        </div>
    );
};

export default Sidebar;