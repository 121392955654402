import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { user_service } from '../../../services/combos';
import { Form } from 'react-bootstrap';

function UserCombo({ onChange, value }) {
  const [inputValue, setInputValue] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleInputChange = async (e) => {
    const value = e.target.value;
    setInputValue(value);
    try {
      const response = await user_service(value);
      setUsers(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedUser) {
      onChange({
        target: {
          name: "UserCombo",
          value: selectedUser.id
        }
      });

    }
  }, [selectedUser]);

  const filteredUsers = users.filter((user) =>
    (user.fullName)?.toLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <div color='#000000'>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="UserCombo">Consumidor</Form.Label>
        <Form.Control
          list="userList"
          name="UserCombo"
          value={selectedUser?.id}
          onChange={(e) =>
            setSelectedUser(
              users.find((user) => user.id === parseInt(e.target.value))
            )
          }
          onInput={handleInputChange}
          placeholder="Selecciona un consumidor..."
        />
        <datalist id="userList" >
          {filteredUsers.map((user) => (
            <option key={user.id} value={user.id} style={{ color: "#000000" }}>
              {user.fullName}
            </option>
          ))}
        </datalist>
        {selectedUser && <p>Selected user: {selectedUser.fullName}</p>}
      </Form.Group>
    </div>
  );
}

export default UserCombo;
