import { useState } from "react";
import { Sidebar as ProSidebar, Menu, MenuItem, useProSidebar, sidebarClasses, menuClasses } from "react-pro-sidebar";
// import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../../pages/theme";

import HomeOutlined from "@mui/icons-material/HomeOutlined";
import InventoryIcon from '@mui/icons-material/Inventory';
import ContactsOutlined from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlined from "@mui/icons-material/ReceiptOutlined";
import PersonOutlined from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlined from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlined from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlined from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlined from "@mui/icons-material/TimelineOutlined";
import MenuOutlined from "@mui/icons-material/MenuOutlined";
import MapOutlined from "@mui/icons-material/MapOutlined";
import GridPedidos from "../pedidos/gridPedidos";



const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
            }}
            onClick={() => setSelected(title)}
            icon={icon}

            component={<Link to={`.${to}`} />}
        >
            <Typography>{title}</Typography>
        </MenuItem>
    );
};

console.log(menuClasses.button)

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { collapseSidebar, collapsed } = useProSidebar();
    const [selected, setSelected] = useState("Dashboard");

    return (
        // <Box
        //     sx={{
        //         "& .css-dip3t8": {
        //             background: `${colors.primary[400]} !important`,
        //         },
        //         "& .pro-sidebar-inner": {
        //             background: `${colors.primary[400]} !important`,
        //         },
        //         "& .pro-icon-wrapper": {
        //             backgroundColor: "transparent !important",
        //         },
        //         "& .pro-inner-item": {
        //             padding: "5px 35px 5px 20px !important",
        //         },
        //         "& .pro-inner-item:hover": {
        //             color: "#868dfb !important",
        //         },
        //         "& .pro-menu-item.active": {
        //             color: "#6870fa !important",
        //         },
        //     }}
        // >
        <div style={{ display: 'flex' }}>

            <ProSidebar collapsed={collapsed}
                rootStyles={{
                    [`.${sidebarClasses.container}`]: {
                        background: `${colors.primary[400]} !important`,
                    },
                    [`.${menuClasses.button}`]: {
                        padding: "5px 35px 5px 20px !important",
                        "&:hover": {
                            background: `${colors.primary[500]} !important`,
                            color: "#868dfb !important",
                        }
                    },
                    [`.${menuClasses.active}`]: {
                        color: "#6870fa !important",
                    },
                }}
            >
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        onClick={() => collapseSidebar()}
                        icon={collapsed ? <MenuOutlined /> : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            // color: colors.grey[100],
                        }}
                    >
                        {!collapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography variant="h3" /*color={colors.grey[100]}*/>
                                    Vendedor
                                </Typography>
                                {/* <IconButton onClick={() => collapseSidebar()}> */}
                                <MenuOutlined />
                                {/* </IconButton> */}
                            </Box>
                        )}
                    </MenuItem>

                    {/* {!collapsed && (
                        <Box mb="25px">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img
                                    alt="profile-user"
                                    width="100px"
                                    height="100px"
                                    src={`../../assets/user.png`}
                                    style={{ cursor: "pointer", borderRadius: "50%" }}
                                />
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    variant="h2"
                                    fontWeight="bold"
                                    sx={{ m: "10px 0 0 0" }}
                                >
                                    {localStorage.getItem("nombre")}
                                </Typography>
                            </Box>
                        </Box>
                    )} */}

                    <Box paddingLeft={collapsed ? undefined : "0%"}>
                        <Item
                            title="Dashboard"
                            to="/"
                            icon={<HomeOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        {/* {!collapsed && (
                            <Typography
                                variant="h6"
                                sx={{ m: "15px 0 5px 20px" }}
                            >
                                Productos
                            </Typography>
                        )}
                        <Item
                            title="Agregar productos"
                            to="/addProductos"
                            icon={<InventoryIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Ver Productos"
                            to="/gridProductos"
                            icon={<ContactsOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        /> */}

                        {!collapsed && (
                            <Typography
                                variant="h6"
                                sx={{ m: "15px 0 5px 20px" }}
                            >
                                Productos ventas
                            </Typography>
                        )}
                        <Item
                            title="Agregar productos (ventas)"
                            to="/addProductosVendedor"
                            icon={<InventoryIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Ver Productos (ventas)"
                            to="/gridProductosVendedor"
                            icon={<ContactsOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        {!collapsed && (
                            <Typography
                                variant="h6"
                                /*color={colors.grey[300]}*/
                                sx={{ m: "15px 0 5px 20px" }}
                            >
                                Pedidos
                            </Typography>
                        )}
                        <Item
                            title="Ver pedidos"
                            to="/gridPedidos"
                            icon={<CalendarTodayOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                    </Box>
                </Menu>
            </ProSidebar>
        </div>
        // </Box>
    );
};

export default Sidebar;