import { useContext } from "react";
import NotificationCountContext from "../hooks/NotificationCountContext";
import { API_URL, getHeaders } from "./config";

export async function vendedorDashboard() {
  const url = `${API_URL}/dashboard/vendedor`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}

export async function adminDashboard() {
  const url = `${API_URL}/dashboard/admin`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}

export async function ventasAdminDashboard(startDate, endDate) {
  const url = `${API_URL}/dashboard/ventasadmin?startDate=${startDate}&endDate=${endDate}`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}

export async function ventasVendedorDashboard(startDate, endDate) {
  const url = `${API_URL}/dashboard/ventasvendedor?startDate=${startDate}&endDate=${endDate}`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}
