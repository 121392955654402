import React, { useEffect, useState } from "react";
import { Container } from "@mui/system";
//import NavBar from "../componentes/Header/Header";
import NavPopular from "../../componentes/nav-popular/NavPopular";
import Carousel from "../../componentes/Carousel/Carousel";
import GridCards from "../../componentes/header/GridCards/GridCards";
import { Navbar } from "react-bootstrap";
// import { FiArrowRight } from "react-icons/fi";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BannerImage from "../../assets/images/home-banner-image.webp";
import "../../Styles/Home.css";
import { Divider, ImageList, Typography } from "@mui/material";
import { ImgListPersonalizado } from "../../componentes/imageList/ImageListPersonalizado";
import { Hotsales } from "../../componentes/imageList/hotsales";

export const Home = () => {

  return (
    <>
      <div className="home-container ">
        <Container>
          <div className="home-banner-container">
            {/* <div className="home-bannerImage-container"> */}
            {/* <img src={BannerBackground} alt="" /> */}
            {/* </div> */}
            <div className="home-text-section">
              {/* <h1 className="primary-heading"> */}
              <Typography variant="h2" className="primary-heading">
                Prendas que cubren tus necesidades
              </Typography>
              {/* </h1> */}
              <Typography variant="h5" className="primary-text">
              Hecha con equipo actualizado, enfocándonos en los detalles.

              </Typography>
              {/* <p className="primary-text">
              </p> */}
              <button className="secondary-button">
                Compra ahora <ArrowForwardIcon />{" "}
              </button>
            </div>
            <div className="home-image-section ">
              <img
                src={BannerImage}
                alt=""
                height="550"
                style={{ borderRadius: "100%" }}
              />
            </div>
          </div>
        </Container>
      </div>

      <Container>
        {/* Hotsales */}
        <div className="shopSection ">
          <Hotsales />
          {/* <ImgListPersonalizado /> */}
        </div>

        {/* Ofertas */}
        <div className="shopSection ">
          <Typography variant="h4" className="titleSection">
            ¡Las mejores ofertas de hoy para ti!
          </Typography>

          <GridCards />
        </div>

        {/* Productos */}
        <div className="shopSection ">
          <Typography variant="h4" className="titleSection">
            Productos populares
          </Typography>

          {/* <GridCards /> */}
          <ImgListPersonalizado />
        </div>
        {/* <Divider sx={{margin:"5%", backgroundColor:"rgb(11, 46, 61)"}}/> */}

        <br />
      </Container>
    </>
  );
};
