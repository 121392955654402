import styled from "styled-components";
import { deepOrange } from "@mui/material/colors";
import theme from "../../../componentes/oldHeader/headerTheme";

export const Wrapper = styled.div`
  .messageRow {
    display: flex;
  }

  .messageRowRight {
    display: flex;
    justify-content: flex-end;
  }

  .messageBlue {
    position: relative;
    margin-left: 20px;
    margin-bottom: 10px;
    padding: 10px;
    // background-color: #a8ddfd;
    background-color: rgb(47 169 225);
    width: 60%;
    /* height: 50px; */
    text-align: left;
    font: 400 0.9em "Open Sans", sans-serif;
    border: 1px solid #97c6e3;
    border-radius: 10px;

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      // border-top: 15px solid #a8ddfd;
      border-top: 15px solid rgb(47 169 225);
      // background-color: rgb(47 169 225);

      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      top: 0;
      left: -15px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-top: 17px solid #97c6e3;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      top: -1px;
      left: -17px;
    }
  }

  .messageOrange {
    position: relative;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 10px;
    // background-color: #f8e896;
    background-color: rgb(255, 255, 255);
    width: 60%;
    /* height: 50px; */
    text-align: left;
    font: 400 0.9em "Open Sans", sans-serif;
    border: 1px solid #000;
    border-radius: 10px;

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      // border-top: 15px solid #f8e896;
      border-top: 15px solid rgb(255, 255, 255);
      // background-color: rgb(255, 255, 255);

      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      top: 0;
      right: -15px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-top: 16px solid #000;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      top: -1px;
      right: -17px;
    }
  }

  .messageContent {
    padding: 0;
    margin: 0;
    min-width: 250px;
    word-wrap: break-word; /* Add this line */
    white-space: pre-wrap; /* Add this line */
  }

  .messageTimeStampRight {
    position: absolute;
    font-size: 0.85em;
    font-weight: 300;
    margin-top: 10px;
    bottom: -3px;
    right: 5px;
  }

  .orange {
    color: ${theme.palette.getContrastText(deepOrange[500])};
    background-color: ${deepOrange[500]};
    width: ${theme.spacing(4)};
    //height: ${theme.spacing(4)};
  }

  .avatarNothing {
    color: transparent;
    background-color: transparent;
    width: ${theme.spacing(4)};
    //height: ${theme.spacing(4)};
  }

  .displayName {
    margin-left: 20px;
  }
`;
