import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#414141",
      main_dark: "0d1117",
    },
    secondary: {
      main: "#F2AA1F",
    },
  },
});

export default theme;
