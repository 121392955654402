import React, { useState, useEffect, useCallback } from "react";
import { Container } from "react-bootstrap";
import {
  getPedidosQuery,
  getPedidosVendedor,
  updatePedidosEstado,
} from "../../../services/pedidos";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Autocomplete,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import Swal from "sweetalert2";

const optionEstatus = [
  { label: "En proceso" },
  { label: "En proceso de entrega" },
  { label: "Orden Entregada" },
];

function GridPedidos(props) {
  const [data, setData] = useState([]);
  const [openDateDialog, setOpenDateDialog] = useState(false);
  const [query, setQuery] = useState({
    status: "",
    cliente: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPedidosVendedor(searchTerm);
        setData(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [searchTerm, handleChange]);

  useEffect(() => {
    const fetchQuery = async () => {
      try {
        const response = await getPedidosQuery(query);
        setData(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchQuery();
  }, [query]);

  const handleQuery = ({ status, cliente }) => {
    if (status)
      setQuery((prevState) => ({
        ...prevState,
        status: status,
      }));
    if (cliente)
      setQuery((prevState) => ({
        ...prevState,
        cliente: cliente,
      }));
  };

  const handleOpenDateDialog = () => {
    setOpenDateDialog(true);
  };

  const handleCloseDateDialog = () => {
    setOpenDateDialog(false);
  };

  const guardarDeliveryDate = () => {
    setOpenDateDialog(false);
  };

  const updateRowState = (index, newValue) => {
    setData((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = {
        ...updatedRows[index],
        order_estado: newValue.label,
      };
      return updatedRows;
    });
  };

  const updateRowDeliveryDate = (index, newValue) => {
    setData((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = {
        ...updatedRows[index],
        order_estado: newValue.label,
      };
      return updatedRows;
    });
  };

  const updatePedido = async (body) => {
    try {
      const response = await updatePedidosEstado(body);
      if (response.status === 200) {
        Swal.fire({
          title: "¡Éxito!",
          text: "Actualizado correctamente",
          icon: "success",
        }).then(() => {});
      } else {
        Swal.fire({
          title: "¡Error!",
          text: response.message,
          icon: "error",
        }).then(() => {});
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Grid container sx={{ paddingY: 3 }}>
        <Grid item sx={{ paddingX: 1 }}>
          <Autocomplete
            disablePortal
            id="buscador-items"
            options={optionEstatus}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Estado" />}
            onChange={(event, newValue) => {
              handleQuery({
                status: newValue.label,
              });
            }}
          />
        </Grid>
        <Grid item sx={{ paddingX: 1 }}>
          <TextField label="Nombre" sx={{ width: 300 }} />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "20%" }}>Nombre producto</TableCell>
              <TableCell style={{ width: "5%" }}>Cantidad</TableCell>
              <TableCell style={{ width: "5%" }}>Precio</TableCell>
              <TableCell style={{ width: "5%" }}>Descuento</TableCell>
              <TableCell style={{ width: "5%" }}>Total</TableCell>
              <TableCell style={{ width: "10%" }}>Estado</TableCell>
              <TableCell style={{ width: "10%" }}></TableCell>
              <TableCell style={{ width: "10%" }}>Fecha de entrega</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.producto_name}
                </TableCell>
                <TableCell>{row.producto_quantity}</TableCell>
                <TableCell>
                  {row.order_items_unit_price > 0
                    ? "MXN $ " + Number(row.order_items_unit_price).toFixed(2)
                    : ""}
                </TableCell>
                <TableCell>
                  {row.order_items_discount > 0
                    ? "MXN $ " + Number(row.order_items_discount).toFixed(2)
                    : ""}
                </TableCell>
                <TableCell>
                  MXN $&nbsp;
                  {(
                    Number(row.producto_quantity) *
                    (Number(row.order_items_unit_price) -
                      Number(row.order_items_discount))
                  ).toFixed(2)}
                </TableCell>
                <TableCell>{row.order_estado}</TableCell>
                <TableCell>
                  <Autocomplete
                    disableClearable
                    id="combo-box-demo"
                    value={{ label: row.order_estado }}
                    options={optionEstatus}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Estado" />
                    )}
                    onChange={(event, newValue) => {
                      if (event.type === "click") {
                        let orderStatus = {
                          idOrder: row.order_id,
                          newStatus: newValue.label,
                        };
                        updatePedido(orderStatus);
                        updateRowState(index, newValue);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="date"
                    type="date"
                    // defaultValue={new Date().toISOString().substr(0, 10)}
                    // defaultValue={new Date().toISOString().substr(0, 10)}
                    value={row.delivery_date ? row.delivery_date : null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event, newValue) => {
                      if (event.type === "click") {
                        let orderStatus = {
                          idOrder: row.order_id,
                          delivery_date: newValue,
                        };
                        updatePedido(orderStatus);
                        updateRowDeliveryDate(index, newValue);
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default GridPedidos;
