import React from 'react'

export const FormatPrice = ({price}) => {
    const formatPriceFunction = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(price);

    return (
        <span>
            {formatPriceFunction}
        </span>
    )
}