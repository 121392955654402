import { API_URL } from "./config";

export async function vendedorInsert(data) {
  const response = await fetch(API_URL + `/vendedores/vendedor`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return await response.json();
}

export async function vendedorUpdate(id, data) {
  const response = await fetch(API_URL + `/vendedores/update/${id}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return await response.json();
}

export async function vendedorget(name) {
  let url = API_URL + `/vendedores/getVendedor`;
  if (name) {
    url += "?name=" + name;
  }
  const response = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return await response.json();
}

export async function getVendedor(id) {
  let url = API_URL + `/vendedores/getVendedor/${id}`;
  const response = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return await response.json();
}

export async function deleteVendedor(id) {
  let url = API_URL + `/vendedores/deleteVendedor/${id}`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  });
  return await response.json();
}