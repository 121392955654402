import { Wrapper } from "./cartItemStyle";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";

const ProductCard = ({ product, removeFromCart, addToCart }) => {
  const navigate = useNavigate();
  const verProducto = () => {
    // navigate("/producto/" + product.id);
    alert('navigate("/producto/" + ' + product.id + ");");
  };

  return (
    <Wrapper>
      <img
        src={
          product.productImageName
            ? product.productImageName
            : "https://dummyimage.com/250x250/fff/aaa"
        }
        alt={product.name}
      />
      <div>
        <CardActionArea onClick={verProducto}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h4" sx={{ margin: "10px" }}>
                {product.name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" align="right">
                {((product.discount * 100) / product.unit_price).toFixed(0) >
                0 ? (
                  <>
                    <span style={{ textDecoration: "line-through" }}>
                      MXN ${(product.quantity * product.unit_price).toFixed(2)}
                    </span>{" "}
                    <span style={{ color: "#ff4747" }}>
                      {((product.discount * 100) / product.unit_price).toFixed(
                        0
                      )}
                      {"% Descuento"}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="h4" align="right">
            {"MXN $" +
              (
                product.quantity *
                (product.unit_price - product.discount)
              ).toFixed(2)}
          </Typography>
        </CardActionArea>

        <CardContent>
          <Typography variant="h3">{product.description}</Typography>
          <Typography variant="h5">{product.colors}</Typography>
          <div>
            <Typography variant="h6">
              Precio por unidad: MXN ${product.unit_price}
            </Typography>
          </div>
          {/* Botones */}
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // marginRight: theme.spacing(2),
              }}
            >
              <IconButton onClick={() => removeFromCart(product)}>
                <RemoveIcon />
              </IconButton>
              <Typography variant="h6">Cantidad: {product.quantity}</Typography>
              <IconButton onClick={() => addToCart(product)}>
                <AddIcon />
              </IconButton>
            </div>
          </div>
        </CardContent>
      </div>
    </Wrapper>
  );
};

export default ProductCard;

/*
<Grid item xs={12} sm={6} md={4} key={product.id}>
            <Card
            // className={classes.root}
            >
              <CardHeader title={product.name} />
              <CardMedia
                image={
                  product.productImageName
                    ? process.env.REACT_APP_BASE_URL +
                      "/images/" +
                      product.productImageName
                    : "https://dummyimage.com/250x250/fff/aaa"
                }
                style={{ height: "150px" }}
              />
              <CardContent>
                <Typography variant="subtitle1">
                  {product.description}
                </Typography>
                <Typography variant="subtitle2">{product.colors}</Typography>
                <Typography variant="h6">
                  Precio: ${product.unit_price}
                </Typography>
                <Typography variant="h6">{product.cantidad}</Typography>
              </CardContent>
            </Card>
          </Grid>

          */
