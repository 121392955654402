import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { changePassword, verifyPasswordToken } from '../../services/LoginService';
import Swal from 'sweetalert2';
import { Container } from 'react-bootstrap';
import { InputLabel, Typography } from '@mui/material';

export const TokenContrasena = () => {
    const params = useParams();

    const navigate = useNavigate();

    const [tokenStatus, setTokenStatus] = useState({});

    useEffect(() => {
        const fetchData = async() => {
            const response = await verifyPasswordToken(params.tokenEmail);

            setTokenStatus(response);
            if (response.status!==200) {
                Swal.fire({
                    title:"¡Error!",
                    text:response.status+"\n "+response.message,
                    icon:"error"
                }).then(() => {
                    navigate("/");
                })

                return
            }
        }

        fetchData();
    }, [params]);
    
    useEffect(() => {
        if(tokenStatus.status===200){
            Swal.fire({
                title: "Nueva contraseña",
                text: "Ya hemos confirmado su cuenta, introduzca su nueva contraseña a continuación.",
                input: "password",
                inputLabel: "Su nueva contraseña",
                showLoaderOnConfirm: true,
                preConfirm: async (password) => {
                    let errorMessage="";
                
                    if (password.length < 8) {
                        errorMessage="Contraseña debe contener al menos 8 caracteres<br/>"
                    } if (password.search(/[a-z]/) < 0) {
                        errorMessage+="Contraseña debe contener al menos una minuscula<br/>";
                    } if (password.search(/[A-Z]/) < 0) {
                        errorMessage+="Contraseña debe contener al menos una mayuscula<br/>";
                    } if (password.search(/[0-9]/) < 0) {
                        errorMessage+="Contraseña debe de contener al menos un numero<br/>";
                    } if (password.search(/[^a-zA-Z\d]/) < 0){ 
                        errorMessage+="Contraseña debe de contener al menos un caracter especial";
                    }
                    if (errorMessage.length>0){
                        Swal.showValidationMessage(
                            "Debes de cumplir los siguientes requisitos:<br/><br/>"+errorMessage
                        );
                    } else {
                        const changePassResponse = await changePassword({emailToken:params.tokenEmail, newPassword:password});

                        console.log(changePassResponse);

                        if (changePassResponse.status!==200){
                            Swal.showValidationMessage(
                                `Algo fallo: <br/> ${changePassResponse.status} <br/> ${changePassResponse.message}`
                            );
                        }

                        return changePassResponse;
                    }
                }
            }).then((values) => {
                if (values.isConfirmed){
                    Swal.fire({
                        icon:"success",
                        title:"Success",
                        text:values.value.message
                    }).then(() => {
                        navigate("/");
                    });
                }
            });
        }

    }, [tokenStatus])

    return (
        <>
            <Container style={{height:"100vh", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <div style={{display:"flex", flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                <Typography variant='h4'>
                    {tokenStatus.status}
                </Typography>
                <br/>
                <Typography variant='h6'>
                    {tokenStatus.message}
                </Typography>
            </div>
        </Container>
        </>
    )
}