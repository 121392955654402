import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import NoPage from "../pages/NoPage";
import Login from "../pages/MainPage/Login";
// import VendedorRoutes from "../pages/Vendedor/VendedorPage";
// import { AdminRouter } from "./AdminRouter";
import { Nosotros } from "../pages/MainPage/Nosotros/Nosotros";
import { Productos } from "../pages/MainPage/Productos";
import { ProductoIndividual } from "../pages/MainPage/ProductoIndividual";
import { Contactanos } from "../pages/MainPage/Contactanos";
import { TermsAndConditions } from "../pages/MainPage/TermsAndConditions";
import { Home } from "../pages/MainPage/Home";
import AdminPage from "../pages/Admin/AdminPage";
import VendedorPage from "../pages/Vendedor/VendedorPage";
import ProdPage from "../pages/Prod/ProdPage";
import Consumidor from "../pages/Consumidor/consumidor";
import { MainFooter } from "../componentes/footer/Mainfooter";
import { EmailAutenticator } from "../pages/MainPage/EmailAutenticator";
import { AccountContext } from "../hooks/AccountContext";
import { TokenContrasena } from "../pages/MainPage/TokenContrasena";

export const MainRouter = ({ socket }) => {
  const { user } = useContext(AccountContext);

  return (
    <Routes>
      <Route path="/">
        <Route
          index
          element={
            <>
              <Home />
              <MainFooter />
            </>
          }
        />
      </Route>
      <Route
        path="/nosotros"
        element={
          <>
            <Nosotros />
            <MainFooter />
          </>
        }
      />
      <Route
        path="/productos"
        element={
          <>
            <Productos />
            <MainFooter />
          </>
        }
      />
      <Route
        path="/producto/:id"
        element={
          <>
            <ProductoIndividual />
            <MainFooter />
          </>
        }
      />

      <Route
        path="/contactanos"
        element={
          <>
            <Contactanos />
            <MainFooter />
          </>
        }
      />

      <Route
        path="/TerminosYCondiciones"
        element={
          <>
            <TermsAndConditions/>
            <MainFooter />
          </>
        }
      />

      {!user ? (
        <>
          <Route
            path="/validarEmail/:tokenEmail"
            element={
              <>
                <EmailAutenticator />
                <MainFooter />
              </>
            }
          />

          <Route path="/login" element={<Login />} />

          <Route
            path="/verificarCambioContrasena/:tokenEmail"
            element={
              <>
                <TokenContrasena />
                <MainFooter />
              </>
            }
          />
        </>
      ) : (
        <>
          <Route
            path="/consumidor/*"
            element={<Consumidor socket={socket} />}
          />
          <Route path="/ventas/*" element={<VendedorPage socket={socket} />} />
          <Route path="/admin/*" element={<AdminPage socket={socket} />} />
          <Route path="/prod/*" element={<ProdPage socket={socket} />} />
        </>
      )}

      <Route path="*" element={<NoPage />} />
    </Routes>
  );
};
