import React from "react";

import { Typography, Container, Box, Grid, Button } from "@mui/material";
import { Wrapper } from "./NosotrosStyle";
import { useNavigate } from "react-router-dom";

export const Nosotros = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className="image-container">
        <img
        src={process.env.REACT_APP_BASE_URL +"/images/stock1.jpg"}
        alt="Banner"
        className="classes_image_banner"
        />
        <div className="dark-overlay"></div>
        <div className="text-overlay">
          <Typography variant="h4" className="classes_title">
            Nuestra historia
          </Typography>
          <Typography
            variant="body1"
            className="classes_text"
            sx={{ paddingBottom: 8 }}
          >
            Desde sus humildes comienzos, Fortepiel se ha convertido en un
            referente en la industria de la moda a mayoristas. Nuestra
            dedicación a la calidad y a la personalización nos ha permitido
            establecer relaciones sólidas y duraderas con nuestros clientes.
          </Typography>
          <Button variant="contained" color="primary" 
            onClick={()=>{
              navigate("/productos");
            }}
          >
            Compra ahora
          </Button>
        </div>
      </div>

      <Container className="classes_root">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <img
              src={process.env.REACT_APP_BASE_URL + "/images/stock3.jpg"}
              alt="Banner"
              className="classes_image"
            />
          </Grid>

          <Grid item xs={12} sm={6} paddingX={5}>
            <Box textAlign={"center"} sx={{ paddingBottom: 8 }}>
              <Typography variant="h4">Nuestra misión</Typography>
            </Box>

            <Grid
              container
              spacing={3}
              sx={{
                alignItems: "center",
                textAlign: "center",
                paddingBottom: 8,
              }}
            >
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">
                  Proporcionar soluciones de moda a medida para nuestros
                  clientes
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">
                  Ser líderes en la adaptación al mercado
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">
                  Impulsar la innovación en la costura
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              sx={{
                alignItems: "center",
                textAlign: "center",
                paddingBottom: 8,
              }}
            >
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">
                  Superar las expectativas del cliente en cada interacción.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">
                  Fomentar un ambiente de colaboración y crecimiento.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">
                  Promover prácticas comerciales responsables y éticas
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} paddingX={5}>
            <Box textAlign={"center"} sx={{ paddingBottom: 8 }}>
              <Typography variant="h4">Nuestros valores</Typography>
              {/* <Typography variant="body1"></Typography> */}
            </Box>

            <Grid
              container
              spacing={3}
              sx={{
                alignItems: "center",
                textAlign: "center",
                paddingBottom: 8,
              }}
            >
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">
                  Excelencia en el servicio al cliente
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">
                  Adaptabilidad al mercado
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">
                  Innovación en técnicas de costura
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              sx={{
                alignItems: "center",
                textAlign: "center",
                paddingBottom: 8,
              }}
            >
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">
                  Compromiso con la satisfacción del cliente
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">
                  Colaboración y trabajo en equipo
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">Responsabilidad y ética</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              src={process.env.REACT_APP_BASE_URL + "/images/stock8.jpg"}
              alt="Banner"
              className="classes_image"
            />
          </Grid>
        </Grid>
      </Container>

      {/* <Container maxWidth="md" className="component-container">
        <Box className="Nuestra misión">
          <Typography variant="h2" className="section-title">
            Nuestra historia
          </Typography>
          <Typography variant="subtitle1">
            Desde sus humildes comienzos, Fortepiel se ha convertido en un
            referente en la industria de la moda a mayoristas. Nuestra
            dedicación a la calidad y a la personalización nos ha permitido
            establecer relaciones sólidas y duraderas con nuestros clientes.
            Muchos de ellos han confiado en nosotros durante más de 20 años, lo
            que es un testimonio de nuestra excelencia y compromiso. Nuestra
            capacidad para adaptarnos y responder rápidamente a las demandas del
            mercado nos ha permitido mantenernos competitivos y relevantes en la
            industria de la moda a mayoristas. Mantenemos una estrecha
            vigilancia sobre las últimas tendencias y tecnologías de fabricación
            para asegurarnos de que nuestras prendas cumplan con los estándares
            más exigentes. En Fortepiel, nos enorgullece mantener relaciones
            sólidas y duraderas con nuestros clientes. A lo largo de los años,
            hemos desarrollado conexiones significativas con clientes que han
            confiado en nosotros durante más de dos décadas. Estas relaciones a
            largo plazo son el resultado de nuestra dedicación al servicio al
            cliente, la calidad de nuestros productos y nuestra capacidad para
            adaptarnos a sus necesidades cambiantes. En Fortepiel, nuestro
            objetivo es seguir siendo un socio confiable y respetado en la
            industria de la moda a mayoristas. Estamos comprometidos a
            proporcionar productos de alta calidad y servicios excepcionales que
            impulsen el éxito de nuestros clientes.
          </Typography>
        </Box>

        <Grid container spacing={3} className="grid-container">
          <Box className="mission-box">
            <Typography variant="h4" className="section-title">
              Nuestra misión
            </Typography>
            <List className="list">
              <ListItem>
                1. Proporcionar soluciones de moda a medida para nuestros clientes.
              </ListItem>
              <Divider className="divider" />
              <ListItem>
              2. Ser líderes en la adaptación al mercado.</ListItem>
              <Divider className="divider" />
              <ListItem>
              3. Impulsar la innovación en la costura.</ListItem>
              <Divider className="divider" />
              <ListItem>
                4. Superar las expectativas del cliente en cada interacción.
              </ListItem>
              <Divider className="divider" />
              <ListItem>
                Fomentar un ambiente de colaboración y crecimiento.
              </ListItem>
              <Divider className="divider" />
              <ListItem>
                Promover prácticas comerciales responsables y éticas.
              </ListItem>
            </List>
          </Box>

          <Grid item xs={12} sm={6} className="grid-item">
            <Box className="values-box">
              <Typography variant="h4" className="section-title">
                Nuestros valores
              </Typography>
              <List className="list">
                <ListItem>Excelencia en el servicio al cliente.</ListItem>
                <Divider className="divider" />
                <ListItem>Adaptabilidad al mercado.</ListItem>
                <Divider className="divider" />
                <ListItem>Innovación en técnicas de costura.</ListItem>
                <Divider className="divider" />
                <ListItem>Compromiso con la satisfacción del cliente.</ListItem>
                <Divider className="divider" />
                <ListItem>Colaboración y trabajo en equipo.</ListItem>
                <Divider className="divider" />
                <ListItem>Responsabilidad y ética.</ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>

        <Box className="contact-box">
          <Typography variant="body1">
            Contact us if you have any questions or inquiries.
          </Typography>
        </Box>
      </Container> */}
    </Wrapper>
  );
};
