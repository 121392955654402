import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  FormGroup,
  FormControl,
  Container,
  Button,
  Form,
} from "react-bootstrap";
import {
  productosPromo,
  setHotsales,
  removeHotSales,
  comboHotSales,
} from "../../../services/productos";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Select from "react-select";
import Swal from "sweetalert2";

function Hotsales(props) {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [formBool, setFormBool] = useState(false);
  const [newDataHostale, setNewDataHostale] = useState([]);

  const [selectProducto, setSelectProducto] = useState(null);
  const [selectDate, setSelectDate] = useState(null);

  const handleChange = useCallback((e) => {
    console.log(e.target.value);
    setSearchTerm(e.target.value);
  }, []);

  const handleEdit = async (id, date) => {
    try {
      const response = await setHotsales(id, date);
      console.log(response);
      if (response.status === 200) {
        Swal.fire({
          title: "¡Éxito!",
          text: "Producto destacado exitosamente",
          icon: "success",
        }).then(() => {
          const updatedData = data.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                is_hotsales: true,
              };
            }
            return item;
          });
          setData(updatedData);
        });
      } else {
        Swal.fire({
          title: "¡Error!",
          text: response.message,
          icon: "error",
        }).then(() => {});
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemove = async (id) => {
    try {
      const response = await removeHotSales(id);
      console.log(response);
      if (response.status === 200) {
        Swal.fire({
          title: "¡Éxito!",
          text: "Producto removido de destacados exitosamente",
          icon: "success",
        }).then(() => {
          const updatedData = data.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                is_hotsales: false,
              };
            }
            return item;
          });
          setData(updatedData);
        });
      } else {
        Swal.fire({
          title: "¡Error!",
          text: response.message,
          icon: "error",
        }).then(() => {});
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await productosPromo(searchTerm);
        setData(response);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [searchTerm]);

  useEffect(() => {
    const getProductoDiscount = async () => {
      try {
        const response = await comboHotSales();
        console.log(response);
        setNewDataHostale(response);
      } catch (error) {
        console.error(error);
      }
    };
    getProductoDiscount();
  }, []);

  const handleSelectChange = (value) => {
    const productId = value.value;
    setSelectProducto(productId);
    console.log("handleSelectChange", productId);
  };

  const handleSelectDateChange = (e) => {
    const selectedDateValue = e.target.value;
    setSelectDate(selectedDateValue);
    console.log("handleSelectDateChange", selectedDateValue);
  };

  const clearSelectForm = () => {
    setSelectProducto(null);
    setSelectDate(null);
    setFormBool(false);
  };

  const loadOptions = () => {
    console.log("loadOptions");
  };

  return (
    <Container>
      <h2> HOT SALES </h2>
      <FormControl
        type="text"
        placeholder="Busca por nombre o descripción"
        className="mb-3"
        value={searchTerm}
        onChange={handleChange}
      />

      {formBool ? (
        <div style={{ color: "#000" }}>
          <h4 style={{ color: "#fff" }}> Agregar producto </h4>
          <Form>
            <Select
              options={newDataHostale}
              loadOptions={loadOptions}
              placeholder="Buscar..."
              className="mb-3"
              onChange={handleSelectChange}
              defaultOptions
            ></Select>
            <FormControl
              type="date"
              className="mb-3"
              placeholder="Fecha"
              onChange={handleSelectDateChange}
            />
            <Button
              className="mb-3"
              variant="warning"
              onClick={() => {
                console.log({ selectProducto, selectDate });
                if (selectProducto != null && selectDate != null) {
                  handleEdit(selectProducto, selectDate);
                  clearSelectForm();
                } else {
                  Swal.fire({
                    title: "¡Error!",
                    text: "campos incompletos",
                    icon: "warning",
                  }).then(() => {});
                }
              }}
            >
              Agregar
            </Button>

            <Button
              className="mb-3"
              variant="danger"
              onClick={() => {
                clearSelectForm();
              }}
            >
              Cancelar
            </Button>
          </Form>
        </div>
      ) : (
        <Button
          variant="warning"
          onClick={() => {
            setFormBool(true);
          }}
        >
          Agregar
        </Button>
      )}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "3%" }}>{/* no. */}</TableCell>
              <TableCell style={{ width: "27%" }}>Nombre</TableCell>
              <TableCell style={{ width: "30%" }}>Descripción</TableCell>
              <TableCell style={{ width: "10%" }}>Tamaño</TableCell>
              <TableCell style={{ width: "10%" }}>Precio unitario</TableCell>
              <TableCell style={{ width: "10%" }}>Descuento</TableCell>
              <TableCell style={{ width: "8%" }}>Cantidad</TableCell>
              <TableCell style={{ width: "7%" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow
                key={item.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  backgroundColor: !item.is_hotsales ? "inherit" : "#196687",
                }}
              >
                <TableCell component="th" scope="row">
                  {item.id}
                </TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.size}</TableCell>
                <TableCell>
                  MXN $&nbsp;{Number(item.unit_Price).toFixed(2)}
                </TableCell>
                <TableCell>
                  - MXN $&nbsp;{Number(item.discount).toFixed(2)}
                </TableCell>
                <TableCell>{item.quantity} Unidad(es)</TableCell>
                <TableCell>
                  {!item.is_hotsales ? (
                    <>
                      {/* <Button
                       variant="warning"
                       onClick={() => handleEdit(item.id)}
                     >
                       Añadir
                     </Button> */}
                    </>
                  ) : (
                    <Button
                      variant="warning"
                      onClick={() => handleRemove(item.id)}
                    >
                      Remover
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default Hotsales;
