import styled from "styled-components";

export const Wrapper = styled.div`
  .placeorder_wrap__inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
`;
