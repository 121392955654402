import styled from "styled-components";

export const Wrapper = styled.div`
  .orderGridStyle {
    border: 1px solid #ccc !important;
    border-radius: 16px;
    margin: 10px 0;
  }

  .listItemStyles {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-around;
    box-shadow: none;
  }

  .orderItemsStyle {
    display: flex;
    align-items: stretch;
    margin-bottom: 16px;
  }

  .imageStyles {
    max-width: 100px;
    max-height: 100px;
    margin-right: 16px;
    display: flex;
  }

  .introStyles {
    color: green;
  }

  .titleStyles {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .descriptionStyles {
    margin-bottom: 8px;
  }

  .linkContainerStyles {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .buttonContainerStyles {
    display: flex;
    align-items: center;
  }

  .linkNoDecoration {
    text-decoration: none;
  }
`;
