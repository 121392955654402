import React, { useState, useEffect, useCallback } from 'react';
import { Table, FormControl, Container, Button } from 'react-bootstrap';
import { productosGet } from '../../../services/productos';
import { useNavigate } from 'react-router-dom';

function GridProductos(props) {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();


  const handleChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await productosGet(searchTerm);
        setData(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [searchTerm, handleChange]);

  const filteredData = data.filter((item) => {
    return item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.description.toLowerCase().includes(searchTerm.toLowerCase())
  }
  );

  const handleEdit = (id) => {
    console.log(`Editar el id : ${id}`);
    navigate(`../updateProductos/${id}`);
  }

  return (
    <Container>
      <FormControl
        type="text"
        placeholder="Busca por nombre"
        className="mb-3"
        value={searchTerm}
        onChange={handleChange}
      />
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>id_Basic_Products</th>
            <th>colors</th>
            <th>fabrics</th>
            <th>size</th>
            <th>unit_Price</th>
            <th>discount</th>
            <th>quantity</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.description}</td>
              <td>{item.id_Basic_Products}</td>
              <td>{item.colors}</td>
              <td>{item.fabrics}</td>
              <td>{item.size}</td>
              <td>{item.unit_Price}</td>
              <td>{item.discount}</td>
              <td>{item.quantity}</td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => handleEdit(item.id)}
                >
                  Modificar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default GridProductos;