import {
  Alert,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Divider,
  Grid,
  Paper,
  Snackbar,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { consumidorAllPedidosCategoria } from "../../services/pedidos";
import { Wrapper } from "./pedidos_style";

const Pedidos = () => {
  const [pedidos, setPedidos] = useState([]);

  const [estadoSeleccionada, setEstadoSeleccionada] = useState(["En proceso"]);
  const estados = ["En proceso", "En proceso de entrega","Orden entregada"]

  const navigate = useNavigate();

    const handleCategoria = (idEstado) => {
      setEstadoSeleccionada([estados[idEstado]]);
    };

  const fetchData = async () => {
    try {
      const response = await consumidorAllPedidosCategoria(estadoSeleccionada);
      setPedidos(response);
    } catch (error) {
      console.error(error);
    }
  };

  function translateMonthToSpanish(dateString) {
    const englishMonths = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    const spanishMonths = [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
      'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];
  
    const dateParts = dateString.split(' ');
    const monthIndex = englishMonths.indexOf(dateParts[2]);
    if (monthIndex !== -1) {
      dateParts[2] = spanishMonths[monthIndex];
    }
  
    return dateParts.join(' ');
  }  

  useEffect(() => {
    fetchData();
  }, [estadoSeleccionada]);
  
  return (
    <Container >
      <br />
        <div className="product-tabs-menu w-tab-menu">
            {estados.map((categoria, index) => (
              <Link
                style={{textDecoration:"none"}}
                className={`product-tab-link ${
                  estadoSeleccionada[0] === categoria ? "selected" : ""
                }`}
                key={index}
                onClick={(e) => {
                  handleCategoria(index);
                }}
              >
                {categoria}
              </Link>
            ))}
        </div>
      <Wrapper>
        <Grid container spacing={2}>
          {pedidos.length > 0 ? (
            <>{pedidos.map((order) => (
              <Grid item xs={12} key={order.id} className="orderGridStyle">
                <Card className="listItemStyles" key={order.id}>
                  <Typography variant="body2" className="introStyles">
                    <CardHeader title={order.status}/>
                  </Typography>
                  <div>
                    <div>
                      <Typography variant="subtitle1" className="titleStyles">
                        <span>
                          <p>{"Orden pedida el día "+translateMonthToSpanish(order.order_date)}</p>
                          {order.delivery_date
                            ? `Llega el día ${translateMonthToSpanish(order.delivery_date)}`
                            : `Esperando confirmación`}
                          &nbsp;&nbsp;
                        </span>
                      </Typography>
                      {order.delivery_date
                      ?(<Typography variant="body2" className="descriptionStyles">
                        <span>
                          Salió del centro de distribución y se dirige hacia{" "}
                          {order.city}, {order.state}
                        </span>
                      </Typography>)
                      :<></>  
                      }
                    </div>
                  </div>
                  <div className="linkContainerStyles">
                    <span>
                      <span>Te respondemos dudas</span>
                    </span>
                    <div>
                      <Link to="/consumidor/chat" className="linkNoDecoration">
                        <span>Enviar mensaje</span>
                      </Link>
                    </div>
                  </div>
                  <div className="buttonContainerStyles">
                    <Button
                      onClick={()=>{
                        navigate("../pedidosIndividuales/"+order.id);
                      }}
                      // order.id
                    >
                      <span>Ver compra</span>
                    </Button>
                  </div>
                </Card>
                {order.items.map((orderItems) => (
                  <div className="orderItemsStyle">
                    <Link
                      to={`/producto/${orderItems.product_id}`}
                      className="linkNoDecoration"
                    >
                      <img className="imageStyles"
                          alt={orderItems.name}
                          src={
                            orderItems.imgPath
                              ? orderItems.imgPath
                              : "https://dummyimage.com/250x250/fff/aaa"
                          }
                      />
                    </Link>
                    <div>
                      <Link
                        to={`/producto/${orderItems.product_id}`}
                        className="linkNoDecoration"
                      >
                        <Typography variant="subtitle1">
                          {orderItems.name}
                        </Typography>
                      </Link>
                      <Typography variant="body2">
                        <span>
                          {orderItems.quantity <= 1
                            ? orderItems.quantity + " Unidad"
                            : orderItems.quantity + " Unidades"}
                        </span>
                      </Typography>
                    </div>
                  </div>
                ))}
              </Grid>
            ))}</>
            ):(
            <Container>
              <div className="d-grid gap-2" style={{ paddingBottom: "25px" }}>
                <Typography variant="h3" style={{ margin: "20px 0" }}>
                  No hay pedidos
                </Typography>
                <Button
                  variant="warning"
                  size="lg"
                  onClick={() => {
                    navigate("/productos");
                  }}
                >
                  Seguir comprando
                </Button>
              </div>
            </Container>
          )}
        </Grid>
      </Wrapper>
    </Container>
  );
};

export default Pedidos;
