import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

const ShowCard = ({ product }) => {
  const porcentajeDes = (
    (product.discount * 100) /
    product.unit_Price
  ).toFixed();

  const navigate = useNavigate();

  // console.log("Debug card: " + JSON.stringify(product));

  const irProducto = (e, id) => {
    e.preventDefault();

    navigate("/producto/" + product.id);
  };

  return (
    // <Card sx={{position: "relative"}} style={{ width: '16rem' }}>
    //   <Card.Header>
    //     <Typography
    //         variant="body"
    //         sx={{ fontWeight: "bold", color: "#ff6d00" }}
    //         >
    //           <span style={{ color: "#ff4747" }}>
    //             {porcentajeDes+"% Descuento !!!!!"}
    //           </span>
    //         </Typography>
    //   </Card.Header>
    //   <img
    //     style={{ width: "height", }}
    //     variant="top"
    //     alt={product.name+"_imagen"}
    //     src={
    //       product.path_img
    //         ? product.path_img
    //         : "https://dummyimage.com/250x250/fff/aaa"
    //     }
    //   />
    //   <Card.Body>
    //       <Card.Title>{product.name}</Card.Title>
    //       <Card.Text>
    //         {product.description}
    //       </Card.Text>
    //       <Button onClick={e=>irProducto(e,product.id)} variant="primary">Ver oferta</Button>
    //     </Card.Body>
    // </Card>
    <div
      className="get-discount-single-item productoCard" /*style={{ width: "16rem"  }} */
    >
      <NavLink
        onClick={(e) => irProducto(e, product.id)}
        style={{ color: "inherit", textDecoration: "none" }}
      >
        <div className="get-discount-content">
          <Typography variant="h5" className="discount-title">
            Ahorra
          </Typography>
          <div className="discount-price">
            {porcentajeDes}
            <span className="text-span-2">%</span>
          </div>
          <div className="discount-paragraph">{product.name}</div>
        </div>
        <div className="get-discount-thumbnail-wrap ">
          <img
            className="discount-image productoImg"
            alt={product.name + "_imagen"}
            src={
              product.path_img
                ? product.path_img
                : "https://dummyimage.com/250x250/fff/aaa"
            }
          />
        </div>
        {/* <img
        style={{ width: "height" }}
        variant="top"
        alt={product.name + "_imagen"}
        src={
          product.path_img
          ? product.path_img
          : "https://dummyimage.com/250x250/fff/aaa"
        }
        />
      <Card.Body> */}
        {/* <Card.Title>{product.name}</Card.Title> */}
        {/* <Card.Text>{product.description}</Card.Text> */}
        {/* <Button onClick={(e) => irProducto(e, product.id)} variant="primary">
          Ver oferta
        </Button> */}
        {/* </Card.Body> */}
      </NavLink>
    </div>
  );
};

export default ShowCard;
