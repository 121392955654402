import { API_URL, getHeaders } from "./config";

export async function reporteDashboard(startDate, endDate) {
  let url =
    API_URL + `/documentos/admin?startDate=${startDate}&endDate=${endDate}`;

  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });

  return await response.json();
}

export async function reporteMasVendidoAdmin(startDate, endDate) {
  let url =
    API_URL +
    `/documentos/adminMasVendidos?startDate=${startDate}&endDate=${endDate}`;

  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });

  return await response.json();
}

export async function reporteMasVendidoVentas(startDate, endDate) {
  let url =
    API_URL +
    `/documentos/ventasMasVendidos?startDate=${startDate}&endDate=${endDate}`;

  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });

  return await response.json();
}

export async function reporteDashboardVentas(startDate, endDate) {
  let url =
    API_URL + `/documentos/ventas?startDate=${startDate}&endDate=${endDate}`;

  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });

  return await response.json();
}
