import React, { useContext, useEffect } from "react";

import { Routes, Route, useNavigate } from "react-router-dom";
//import PedidosProduccion from "../prod/components/pedidos/pedidos-produccion";
import GridPedidos from "../componentes/pedidos/pedidos-produccion";
// import GridVentas from "../admin/components/usuarios-ventas/grid-ventas";
// import FormProductosBasicos from "../admin/components/basic-productos/form-basic-productos";
// import GridProductosBasicos from "../admin/components/basic-productos/grid-basic-productos";
// import FormProductos from "../admin/components/productos/form-productos";
// import GridProductos from "../admin/components/productos/grid-productos";
// import FormDetalles from "../admin/components/orden-detalles/form-detalles";
// import GridDetalles from "../admin/components/orden-detalles/grid-detalles";
// import Dashboard from "../admin/components/dashboard/index.jsx";
// import UpdateVentas from "../admin/components/usuarios-ventas/update-ventas";
// import UpdateProductos from "../admin/components/productos/update-productos";
// import Hotsales from "../admin/components/hotsales/Hotsales";

export const ProdRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<GridPedidos />} />
      {/* <Route path="/" element={<Dashboard />} /> */}
      {/* Ventas */}
      {/* <Route path="/addVentas" element={<FormVentas />} /> */}
      {/* <Route path="/verVentas" element={<GridVentas />} /> */}
      {/* <Route path="/updateVentas/:id" element={<UpdateVentas />} /> */}
      {/* Productos basicos ? */}
      {/* <Route path="/addProductosBasicos" element={<FormProductosBasicos />} /> */}
      {/* <Route path="/verProductosBasicos" element={<GridProductosBasicos />} /> */}
      {/* Productos */}
      {/* <Route path="/addProductos" element={<FormProductos />} /> */}
      {/* <Route path="/verProductos" element={<GridProductos />} /> */}
      {/* <Route path="/updateProductos/:id" element={<UpdateProductos />} /> */}
      {/* Ordenes */}
      {/* <Route path="/addOrdenes" element={<FormDetalles />} /> */}
      {/* <Route path="/verOrden" element={<GridDetalles />} /> */}
      {/* hotsales */}
      {/* <Route path="/hotsales" element={<Hotsales />} /> */}
    </Routes>
  );
};
