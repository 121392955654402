import React, { useState, useEffect, useCallback } from "react";
import { Container, Button } from "react-bootstrap";
import { getPedidosVendedor } from "../../../services/pedidos";
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Table,
  Grid,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";

function GridPedidos(props) {
  const { id } = useParams();

  return (
    <Container>
      <Grid
        container
        spacing={3}
        sx={{ alignItems: "center", paddingBottom: 8 }}
      ></Grid>
    </Container>
  );
}

export default GridPedidos;
