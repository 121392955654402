import Swal from "sweetalert2";
import { addToCheckout } from "../../../services/checkout";
import { Wrapper } from "./ResumenCheckoutStyle";
import { Typography } from "@mui/material";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";

const ResumenCheckout = ({ products, precioTotal, customerInfo }) => {
  const navegate = useNavigate();

  const crearPedido = async (paypal_request_id) => {
    try {
      let orderData = customerInfo.orderData;
      orderData.paypal_request_id = paypal_request_id;
      console.log(orderData);
      const productosResponse = await addToCheckout(orderData);
      if (productosResponse.status === 200) {
        Swal.fire({
          title: "¡Éxito!",
          text: "Producto basico añadido exitosamente",
          icon: "success",
        }).then(() => {
          navegate("/consumidor/carrito");
        });
      } else {
        Swal.fire({
          title: "¡Error!",
          text: productosResponse.message,
          icon: "error",
        }).then(() => {});
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Wrapper>
      <div className="sideContainer">
        <div className="pl-block-container">
          <Typography variant="h3">Resumen</Typography>

          {products.map((item) => (
            <div className="pl-summary__item-pc">
              <Typography variant="body1" className="pl-summary__item-title-pc">
                <span>{item.name}</span>
              </Typography>
              <Typography
                variant="body2"
                className="pl-summary__item-content-pc"
              >
                <span>
                  {"MXN $" +
                    (item.quantity * (item.unit_price - item.discount)).toFixed(
                      2
                    )}
                </span>
              </Typography>
            </div>
          ))}

          <Typography variant="h6">Coste total de los artículos</Typography>
          <div className="pl-summary__item-pc">
            <Typography variant="h5" className="pl-summary__item-title-pc">
              <span>Total</span>
            </Typography>
            <Typography variant="h5" className="pl-summary__item-content-pc">
              <span>MXN ${precioTotal}</span>
            </Typography>
          </div>
        </div>

        {customerInfo.validated ? (
          <div className="pl-wrap-container">
            <PayPalScriptProvider
              options={{
                "client-id":
                  "AforG8kADxq4xKJuuFWgAIvM-sWzMq_Z9K81_698LpxWdDLYEx-F6Zw-9C7DX0wBPM2fqM9NCeHHgBVd",
                currency: "MXN",
              }}
            >
              <PayPalButtons
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: precioTotal,
                        },
                      },
                    ],
                  });
                }}
                onApprove={(data, actions) => {
                  return actions.order.capture().then(function (details) {
                    console.log(details);
                    crearPedido(details.id);
                  });
                }}
              />
            </PayPalScriptProvider>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Wrapper>
  );
};

export default ResumenCheckout;
