import { Alert, Box, Button, Grid, Snackbar, Typography } from "@mui/material";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { addToCarrito, getCarritoNotificaciones } from "../../services/carrito";
import { useContext } from "react";
import NotificationCountContext from "../../hooks/NotificationCountContext";
import { FormatPrice } from "../FormatPrice/FormatPrice";

const Producto = ({ product }) => {
  const {
    id,
    name,
    productImageName,
    price,
    category,
    description,
    discount,
    quantity,
  } = product;
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    status: false,
    message: "",
  });
  const navigate = useNavigate();
  const { updateNotificationCount } = useContext(NotificationCountContext);
  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const addToCar = async () => {
    const user_id = localStorage.getItem("userid");
    if (!user_id) {
      navigate("/login");
      return;
    }
    try {
      const response = await addToCarrito({
        product_id: id,
        quantity: 1,
        user_id,
      }).then((res) => {
        updateNotificationCount();
        return res;
      });
      setMessage({ status: true, message: response.message });
      handleOpen();
    } catch (error) {
      setMessage({
        status: false,
        message: "`Error al agregar el producto al carrito`",
      });
      handleOpen();
    }
  };
  const prod_mui = (
    <Grid item md={3}>
      <NavLink
        to={"/producto/" + id}
        style={{ color: "inherit", textDecoration: "none" }}
        className="productoCard"
      >
        <Box sx={{ width: "250px", height: "250px" }}>
          <img
            style={{
              display: "block",
              objectFit: "contain",
              margin: "auto",
              width: "100%",
              height: "100%",
            }}
            className="productoImg"
            alt={name}
            src={
              productImageName
                ? productImageName
                : "https://dummyimage.com/250x250/fff/aaa"
            }
          />
        </Box>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {name}
        </Typography>
        <Typography variant="body2">{description}</Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold", color: "#ff6d00" }}>
          {/* MXN${price} */}
          MXN $ <FormatPrice price={(price - discount).toFixed(2)}/>
        </Typography>
        {((discount * 100) / price).toFixed(0) > 0 ? (
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", color: "#ff6d00" }}
          >
            <span style={{ color: "#ff4747" }}>
              <FormatPrice price={((discount * 100) / price)}/>
              {"% Descuento"}
            </span>
          </Typography>
        ) : (
          <></>
        )}
      </NavLink>
      <Typography variant="subtitle2">Disponibles: {quantity}</Typography>
      {quantity > 0 ? (
        <Button onClick={addToCar} variant="outlined">
          Agregar al carrito
        </Button>
      ) : (
        <Typography variant="subtitle1" color={"red"}>
          No hay disponibles
        </Typography>
      )}

      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={message ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {message.message}
        </Alert>
      </Snackbar>
    </Grid>
  );

  return prod_mui;
};

export default Producto;
