export const PORT = window.location.protocol === "http:" ? ":5000" : ":5001";

export const API_URL = process.env.NODE_ENV
  ? process.env.REACT_APP_BASE_URL + "/api"
  : "https://fortepiel.mx" + PORT + "/api";

export const API_URL_PATH = process.env.NODE_ENV
  ? process.env.REACT_APP_BASE_URL
  : "https://fortepiel.mx" + PORT;

export const getSessionToken = () => {
  if (localStorage.getItem("accessToken") != null)
    return localStorage.getItem("accessToken");
  return null;
};

export const getHeaders = () => {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    authorization: getSessionToken(),
  };
};
