import React, { createContext, useEffect, useState } from "react";

export const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const initialUserData = {
    accessToken: localStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken"),
    userid: localStorage.getItem("userid"),
    nombre: localStorage.getItem("nombre"),
    tipo: localStorage.getItem("tipo"),
  };

  const [user, setUser] = useState(
    Object.values(initialUserData).every((value) => value === null)
      ? undefined
      : initialUserData
  );

  useEffect(() => {
    try {
      if (user && user.accessToken !== null && user.accessToken !== null) {
        localStorage.setItem("accessToken", user.accessToken);
        localStorage.setItem("refreshToken", user.refreshToken);
        localStorage.setItem("userid", user.userid);
        localStorage.setItem("nombre", user.nombre);
        localStorage.setItem("tipo", user.tipo);
      } else {
        setUser();
      }
    } catch (err) {
      console.log("Usuario inexistente");
    }
  }, [user]);

  // Function to update user data
  const updateUser = (newUserData) => {
    setUser(newUserData);
  };

  return (
    <AccountContext.Provider value={{ user, updateUser }}>
      {children}
    </AccountContext.Provider>
  );
};
