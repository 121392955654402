import { Box } from '@mui/system';
import { Form, Button, Container } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { vendedorUpdate, getVendedor } from '../../../services/vendedor';
import Swal from "sweetalert2";
import { useParams } from 'react-router-dom';

const UpdateVentas = () => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    first_Name: "",
    last_Name: "",
    email: "",
    phone: "",
    id_u_Type: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getVendedor(id);
        setFormData(response[0]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData);

    if (formData.password !== formData.confirmPassword) {
      Swal.fire({
        title: "Error",
        text: "Las contraseñas deben de ser iguales",
        icon: "error",
      }).then(() => {
      });
      return;
    }

    try {
      const vendedorResponse = await vendedorUpdate(id, formData);
      if (vendedorResponse.status === 200) {
        Swal.fire({
          title: "¡Éxito!",
          text: "Vendedor actualizado exitosamente",
          icon: "success",
        }).then(() => {
        });

      } else {
        console.log(vendedorResponse);
        Swal.fire({
          title: "¡Error!",
          text: vendedorResponse.message,
          icon: "error",
        }).then(() => {
        });
      }
      // console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Container>
      <h4> Editar personal </h4>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicFirstName">
          <Form.Label>Nombre(s)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Entra nombre"
            name="first_Name"
            value={formData.first_Name}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicLastName">
          <Form.Label>Apellidos</Form.Label>
          <Form.Control
            type="text"
            placeholder="Introducir apellidos"
            name="last_Name"
            value={formData.last_Name}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Correo electrónico</Form.Label>
          <Form.Control
            type="email"
            placeholder="Introducir correo electrónico"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <Form.Text className="text-muted">
            {/* We'll never share your email with anyone else. */}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPhone">
          <Form.Label>Teléfono</Form.Label>
          <Form.Control
            type="tel"
            placeholder="Introducir teléfono"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicUser">
            <Form.Label>Tipo de usuario</Form.Label>
            <Form.Select
                name="id_u_Type"
                onChange={handleChange}
                value={formData.id_u_Type}
                >
                <option value="2">Vendedor</option>
                <option value="3">Operaciones</option>
            </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Contraseña</Form.Label>
          <Form.Control
            type="password"
            placeholder="Contraseña"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
          <Form.Label>Confirmar contraseña</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirmar contraseña"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Actualizar
        </Button>
      </Form>
    </Container>
  );
}

export default UpdateVentas;