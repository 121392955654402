import { API_URL, getHeaders, getSessionToken } from "./config";

export async function productosInsert(data) {
  const formData = new FormData();

  // Append each file to the form data object
  data.imageUpload.forEach((file) => {
    formData.append("imageUpload", file);
  });

  // Append the rest of the data to the form data object
  formData.append("name", data.name);
  formData.append("description", data.description);
  formData.append("colors", data.colors);
  formData.append("fabrics", data.fabrics);
  formData.append("size", data.size);
  formData.append("price", data.price);
  formData.append("discount", data.discount);
  formData.append("IdProductoBasico", data.IdProductoBasico);
  formData.append("quantity", data.quantity);

  const response = await fetch(API_URL + `/productos/addProducto`, {
    method: "POST",
    body: formData,
    headers: {
      Accept: "application/json",
      authorization: getSessionToken(),
    },
  });

  return await response.json();
}

export async function productosModify(data) {
  const formData = new FormData();

  // Append each file to the form data object
  if (data.imageUpload){
    data.imageUpload.forEach((file) => {
      formData.append("imageUpload", file);
    });
  }else{
    formData.append("imageUpload", "");
  }

  // Append the rest of the data to the form data object
  formData.append("idProduct", data.id);
  formData.append("name", data.name);
  formData.append("description", data.description);
  formData.append("colors", data.colors);
  formData.append("fabrics", data.fabrics);
  formData.append("size", data.size);
  formData.append("price", data.unit_Price);
  formData.append("discount", data.discount);
  formData.append("IdProductoBasico", data.id_Basic_Products);
  formData.append("quantity", data.quantity);

  const response = await fetch(API_URL + `/productos/updateProducto`, {
    method: "POST",
    body: formData,
    headers: {
      Accept: "application/json",
      authorization: getSessionToken(),
    },
  });

  return await response.json();
}

export async function productosGet(data) {
  let url = API_URL + `/productos/getProducto?`;
  if (data.name) {
    url += "&name=" + data.name;
  }
  if (data.empresa) {
    url += "&empresa=" + data.empresa;
  }
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}

export async function productoGet(id) {
  let url = API_URL + `/productos/getProducto/${id}`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}

export async function productoPromosGet(numProdProm) {
  let url = API_URL + `/productos/promoProductos/${numProdProm}`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}

export async function productoMostSoldGet(numProdSold) {
  let url = API_URL + `/productos/mostSoldProductos/${numProdSold}`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}

export async function productosPromo(search) {
  let url = API_URL + `/productos/hotsales?search=` + search;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}

// export async function setHotsales(id) {
//   let url = API_URL + `/productos/setHotsales`;
//   const response = await fetch(url, {
//     method: "POST",
//     headers: getHeaders(),
//     body: JSON.stringify({ id }),
//   });
//   return await response.json();
// }

export async function comboHotSales() {
  let url = API_URL + `/productos/comboHotSales`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}

export async function setHotsales(id, date) {
  let url = API_URL + `/productos/setHotsales`;
  const response = await fetch(url, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ id, date }),
  });
  return await response.json();
}

export async function removeHotSales(id) {
  let url = API_URL + `/productos/removeHotSales`;
  const response = await fetch(url, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ id }),
  });
  return await response.json();
}

export async function productoHotsales() {
  let url = API_URL + `/libre/hotsales`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}
