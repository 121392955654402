import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { consumidorPedidos } from "../../../services/pedidos";
import { Wrapper } from './PedidosIndividualesStyle';
import { PedidoIndividualItem } from './PedidoIndividualItem/PedidoIndividualItem';
import { Container, Divider, Grid, Paper, Typography } from "@mui/material";
import { FormatPrice } from '../../../componentes/FormatPrice/FormatPrice'
import { Button } from "react-bootstrap";

export const PedidosIndividuales = () => {
    const { id } = useParams();

    const [pedido, setPedido] = useState(
        {
            "id": "2",
            "order_date":"2023-06-21T00:57:32.000Z",
            "items": [{ "items": "prueba" }],
        });

    const fetchData = async () => {
        try {
            const response = await consumidorPedidos(id);
            setPedido(response[0]);
            console.log(response);
        } catch (error) {
            console.error(error);
        }
    }

    let formatoFecha = (fecha) =>{
        let date = new Date(fecha);

        let day = date.getDate();

        let options = {month: "long"};
        let month = new Intl.DateTimeFormat("es-ES",options).format(date);

        let year = date.getFullYear();

        return (`${day} de ${month} del ${year}`);
    }

    const calculateTotal = (items) =>
    items.reduce(
      (acc, item) => acc + item.quantity * (item.unit_price - item.discount),
      0
    );

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <Wrapper>
            <Container>
                <div className="product-display">
                    <Typography variant="h3" style={{ marginTop: "15px" }}>
                        {"Id del Pedido: "+id}
                    </Typography>
                    <Divider/>
                    <Typography variant="h4" style={{ marginBottom: "25px" }}>
                        Detalles del pedido
                    </Typography>
                    <Grid container spacing={2} className='productos'>
                        <Grid item xs={8} style={{margin: " 10px 0"}}>
                            <Typography variant="h4" style={{ marginBottom: "25px" }}>
                                Subtotal ({pedido.items.length} productos):
                            </Typography>
                            {pedido.items.map((producto,index) => (
                                <>
                                    <PedidoIndividualItem
                                        key={producto.product_id}
                                        product={producto}
                                    />
                                    <Divider/>
                                </>
                            ))}
                        </Grid>

                        <Grid item xs={3} >
                            <Paper elevation={1} style={{padding:"5px 25px"}}>
                                <Typography variant="h4" style={{ margin: "5px 0" }}>
                                    Información 
                                </Typography>
                                <Typography variant="h6">
                                    <div style={{fontWeight:"bold"}}>Estado: </div>{pedido.status}
                                </Typography>
                                <Typography variant="h6">
                                    <div style={{fontWeight:"bold"}}>Fecha comprada: </div>{formatoFecha(pedido.order_date)}
                                </Typography>
                                <Typography variant="h6">
                                <div style={{fontWeight:"bold"}}>Fecha de entrega: </div>
                                    {
                                        pedido.delivery_date ?
                                        formatoFecha(pedido.delivery_date)
                                        :" No se ha establecido"
                                    }
                                </Typography>
                                <Typography variant="h6">
                                    <div style={{fontWeight:"bold"}}>Domicilio de Entrega: </div>
                                    <p>{" "+pedido.address}</p>
                                </Typography>
                                <Typography variant="h6">
                                    <div style={{fontWeight:"bold"}}>Total: </div>MXN $ <FormatPrice price={calculateTotal(pedido.items)}/>
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </Wrapper>
    );
};