import { Button, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { Wrapper } from "./textInputStyle";
import { getSocket } from "../../../socket";
import { addMessageToConversation } from "../../../services/chat";
import { AccountContext } from "../../../hooks/AccountContext";

export const TextInput = ({
  handleNewMessage,
  receiverId,
  conversartionId,
}) => {
  const socket = getSocket();
  const [message, setMessage] = useState("");
  const { user } = useContext(AccountContext);

  const sendMessage = async () => {
    const newTimestamp = new Date();
    const formattedNewTimestamp = `${newTimestamp.getFullYear()}-${(
      newTimestamp.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${newTimestamp
      .getDate()
      .toString()
      .padStart(2, "0")} ${newTimestamp
      .getHours()
      .toString()
      .padStart(2, "0")}:${newTimestamp
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${newTimestamp
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;

    const newMessage = {
      conversation_id: conversartionId,
      formatted_timestamp: formattedNewTimestamp,
      message_text: message,
      sender_id: user.userid,
      timestamp: newTimestamp,
    };
    if (message.trim() !== "") {
      socket.emit("sendMessage", {
        senderId: user.userid,
        receiverId: receiverId,
        message: newMessage,
        type: "",
      });

      let senderMessage = {
        id_conversation: conversartionId,
        id_sender: user.userid,
        message: message,
      };
      console.log({ senderMessage });
      const response = await addMessageToConversation(senderMessage).then(
        () => {
          socket.emit("handleNewAlert", user.userid);
        }
      );
      handleNewMessage({ message: newMessage, senderId: user.userid });

      setMessage("");
    }
  };

  return (
    <Wrapper>
      <form
        className={"wrapForm"}
        noValidate
        autoComplete="off"
        onSubmit={(event) => {
          event.preventDefault();
          sendMessage();
        }}
      >
        <TextField
          id="standard-text"
          label="Mensaje"
          className={"wrapText"}
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          className={"button"}
          onClick={sendMessage}
        >
          <SendIcon />
        </Button>
      </form>
    </Wrapper>
  );
};
