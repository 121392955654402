import CheckoutForm from "../checkoutForm";
import ResumenCheckout from "../ResumenCheckout/resumenCheckout";
import { Wrapper } from "./checkoutStyle";
import { Container } from "@mui/material";
import { getCartCheckout } from "../../../services/checkout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const [customerInfo, setCustomerInfo] = useState({
    validated: false,
    orderData: {},
  });
  const [products, setProducts] = useState([]);
  const [precioTotal, setPrecioTotal] = useState(0);
  const navegate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await getCartCheckout();
      setProducts(response);
      calculateTotal(response);
      if (response.lenght <= 0) navegate("/productos");
    } catch (error) {
      console.error(error);
    }
  };

  const calculateTotal = (items) => {
    setPrecioTotal(
      items
        .reduce(
          (acc, item) =>
            acc + item.quantity * (item.unit_price - item.discount),
          0
        )
        .toFixed(2)
    );
  };
  useEffect(() => {
    calculateTotal(products);
  }, [products]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <Container>
        {/* {<h2> {customerInfo.validated ? "true" : "false"}</h2>} */}
        <div className="placeorder_wrap__inner">
          <CheckoutForm
            precioTotal={precioTotal}
            setCustomerInfo={setCustomerInfo}
          />
          <ResumenCheckout
            products={products}
            precioTotal={precioTotal}
            customerInfo={customerInfo}
          />
        </div>
      </Container>
    </Wrapper>
  );
};

export default Checkout;
