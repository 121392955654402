import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import { NavLink } from 'react-router-dom';

import MapIcon from '@mui/icons-material/Map';
import Phone from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import Logo from '../oldHeader/logo/logo';
import theme from '../oldHeader/headerTheme';

export const MainFooter = () => {
    let tabs = [
        { nombre: 'Hogar', ruta: '/' },
        { nombre: 'Sobre nosotros', ruta: '/nosotros' },
        { nombre: 'Productos', ruta: '/productos' },
        { nombre: 'Contáctanos', ruta: '/contactanos' },
        { nombre: 'Inicia sesión', ruta: '/login' },
        { nombre: 'Términos y condiciones', ruta: '/TerminosYCondiciones' }
    ];

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="footer">
            <ThemeProvider theme={theme}>
                <Grid
                    position="static"
                    spacing={2}
                    marginTop="0px"
                    paddingBottom="0px"
                    sx={{ backgroundColor: theme.palette.primary.dark, marginTop: 6 }}
                >
                    <NavLink to="#" style={{ color: 'inherit', textDecoration: 'none' }} onClick={scrollToTop}>
                        <Typography sx={{ textAlign: "center", padding: 3 }} variant="body1" color="white" >
                            <ArrowDropUpIcon />
                            Inicio de página
                            <ArrowDropUpIcon />
                        </Typography>
                    </NavLink>

                </Grid>
                <Grid
                    container
                    position="static"
                    spacing={2}
                    marginTop="0px"
                    paddingBottom="0px"
                    sx={{ backgroundColor: theme.palette.primary.main }}
                >
                    <Grid item xs={12} md={5}>
                        {/* <Box display="flex" justifyContent="center">
                            <Logo logoHeight="150" />
                        </Box> */}
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box>
                            <Typography variant="h6" color="white" gutterBottom>
                                Links rápidos
                            </Typography>
                            <List>
                                {tabs.map((tab) => (
                                    <NavLink
                                        to={tab.ruta}
                                        style={{ color: 'orange', textDecoration: 'none' }}
                                    >
                                        <ListItem key={'footer-' + tab.nombre}>
                                            <Typography variant="body2" color="white" textAlign="center">
                                                {tab.nombre}
                                            </Typography>
                                        </ListItem>
                                    </NavLink>
                                ))}
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box>
                            <Typography variant="h6" color="white" gutterBottom>
                                Contáctanos
                            </Typography>
                            <List>
                                <ListItem key="contactos-1">
                                    <ListItemIcon>
                                        <MapIcon style={{ color: 'white' }} fontSize="large" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="body2" color="white" textAlign="left">
                                            Av Joaquín Amaro 1478, Colonia Oblatos, 44700
                                            Guadalajara, Jalisco, México
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem key="contactos-2">
                                    <ListItemIcon>
                                        <Phone style={{ color: 'white' }} fontSize="large" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="body2" color="white" textAlign="left">
                                            +52 (33) 3355 6536
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem key="contactos-3">
                                    <ListItemIcon>
                                        <MailIcon style={{ color: 'white' }} fontSize="large" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="body2" color="white" textAlign="left">
                                            marcos@chamarrasforte.com.mx
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        {/* Additional grid item for spacing, adjust the size as needed */}
                    </Grid>
                </Grid>
            </ThemeProvider>
        </div >
    );
};