import { Container, Grid, Typography } from "@mui/material"
import AppWidgetSummary from "./AppWidgetSummary"
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import PaidIcon from '@mui/icons-material/Paid';
import { useEffect, useState } from "react";
import { adminDashboard } from "../../../services/dashboard";
import Graficas from "./Graficas";
import { Link } from 'react-router-dom';

const Dashboard = () => {

    const [ordenes_hechas, setordenes_hechas] = useState(false);
    const [unidad_vendida, setunidad_vendida] = useState(false);
    const [ganancias, setganancias] = useState(false);
    const [ordenes_hechas_semana, setordenes_hechas_semana] = useState(false);
    const [unidad_vendida_semana, setunidad_vendida_semana] = useState(false);
    const [ganancias_semana, setganancias_semana] = useState(false);

    const fetchData = async () => {
        try {
            const response = await adminDashboard();
            setordenes_hechas(response[0].ordenes_hechas);
            setunidad_vendida(response[0].unidad_vendida);
            setganancias(response[0].ganancias);
            setordenes_hechas_semana(response[0].ordenes_hechas_semana);
            setunidad_vendida_semana(response[0].unidad_vendida_semana);
            setganancias_semana(response[0].ganancias_semana);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>

            <Container maxWidth="xl">
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Hola! Bienvenido otra vez.
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Link to="./verOrdenes" style={{ textDecoration: 'none' }} >
                            <AppWidgetSummary title="Ordenes hechas" total={ordenes_hechas + " Ordenes"} icon={<ShoppingCartIcon />} />
                        </Link>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Link to="./verProductos" style={{ textDecoration: 'none' }} >
                            <AppWidgetSummary title="Unidades vendidas" total={unidad_vendida + " Productos"} color="info" icon={<ShoppingBasketIcon />} />
                        </Link>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Link to="./verOrdenes" style={{ textDecoration: 'none' }} >
                            <AppWidgetSummary title="Ganancias" total={ganancias} color="warning" icon={<PaidIcon />} />
                        </Link>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Link to="./verOrdenes" style={{ textDecoration: 'none' }} >
                            <AppWidgetSummary title="Ordenes (semana)" total={ordenes_hechas_semana + " Ordenes"} color="error" icon={<ShoppingCartIcon />} />
                        </Link>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Link to="./verOrdenes" style={{ textDecoration: 'none' }} >
                            <AppWidgetSummary title="Unidades vendidas (semana)" total={unidad_vendida_semana + " Productos"} color="error" icon={<ShoppingBasketIcon />} />
                        </Link>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Link to="./verOrdenes" style={{ textDecoration: 'none' }} >
                            <AppWidgetSummary title="Ganancias (semana)" total={ganancias_semana} color="error" icon={PaidIcon} />
                        </Link>
                    </Grid>

                </Grid>
                <Graficas />
            </Container>
        </>
    )
}

export default Dashboard