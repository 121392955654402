import { Container } from "@mui/material";
import ChatComponent from "./chatComponent/chatComponent";
import ChatManager from "./chatManager/chatManager";
import {
  getAllConversation,
  getConversation,
  messagesAlert,
} from "../../services/chat";
import { useContext, useEffect, useState } from "react";
import useSocketSetup from "../../useSocketSetup";
import { getSocket } from "../../socket";
import { AccountContext } from "../../hooks/AccountContext";
import NotificationCountContext from "../../hooks/NotificationCountContext";

const Chat = () => {
  useSocketSetup();
  const socket = getSocket();

  const [conversations, setConversations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [receiverId, setReceiverId] = useState(null);
  const [conversartionId, setConversartionId] = useState(null);
  const { user } = useContext(AccountContext);
  const { setMensageNoti } = useContext(NotificationCountContext);

  const fetchData = async () => {
    try {
      const response = await getAllConversation();
      setConversations(response);
      cambiarChat(
        response[0].conversation_partner_id,
        response[0].user1_id == localStorage.getItem("userid")
          ? response[0].user2_id
          : response[0].user1_id
      );
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMessages = async (newId) => {
    try {
      const response = await getConversation(newId);
      console.log(response);
      const responseXD = await getConversation(newId);
      setMessages(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Socket event listeners
    socket.on("newMessage", handleNewMessage);
    return () => {
      // Cleanup socket event listeners
      socket.off("newMessage", handleNewMessage);
    };
  }, [receiverId]);

  const handleNewMessage = ({ message, senderId }) => {
    socket.emit("refreshChatAlert", user.userid);
    if (receiverId == senderId || user.userid == senderId) {
      setMessages((prevMessages) => [...prevMessages, message]);
    } else {
    }
  };

  const cambiarChat = (newId, newReceiverId) => {
    fetchMessages(newId);
    setConversartionId(newId);
    setReceiverId(newReceiverId);
    socket.emit("refreshChatAlert", user.userid);
  };

  return (
    <Container>
      {/* <h2 style={{ textAlign: "center" }}>{receiverId}</h2> */}
      <br></br>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          fontFamily: "sans-serif",
        }}
      >
        <ChatManager conversations={conversations} cambiarChat={cambiarChat} />
        <ChatComponent
          messages={messages}
          handleNewMessage={handleNewMessage}
          receiverId={receiverId}
          conversartionId={conversartionId}
        />
      </div>
    </Container>
  );
};
export default Chat;
