import React, { useContext, useEffect } from "react";

import { Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "../admin/components/dashboard/index.jsx";
import Carrito from "../consumidor/carrito/carrito.js";
import Checkout from "../consumidor/checkout/checkout/checkout.js";
import Chat from "../consumidor/chat/chat.js";
import Pedidos from "../consumidor/pedidos/pedidos.js";
import { PedidosIndividuales } from "../consumidor/pedidos/PedidosIndividuales/PedidosIndividuales.js";

export const ConsumidorRouter = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Dashboard />} /> */}
      <Route path="/carrito" element={<Carrito />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/chat" element={<Chat />} />
      <Route path="/pedidos" element={<Pedidos />} />
      <Route path="/pedidosIndividuales/:id" element={<PedidosIndividuales/>} />

      {/* pedidos */}
    </Routes>
  );
};
