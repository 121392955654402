import { useContext } from "react";
import NotificationCountContext from "../hooks/NotificationCountContext";
import { API_URL, getHeaders } from "./config";

export async function addToCarrito(data) {
  const { user_id, product_id, quantity } = data;
  if (!quantity) {
    data.quantity = 1;
  }

  const url = `${API_URL}/users/carrito/addToCarrito`;
  const response = await fetch(url, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
  return await response.json();
}

export async function getCarritoNotificaciones() {
  const url = `${API_URL}/users/carrito/getCarritoNotificaciones`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  const data = await response.json();
  localStorage.setItem("carritoNotificacion", data[0].total_quantity);
  return data;
}
