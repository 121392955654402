import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { productosInsert, productoGet } from '../../../services/productos';
import { productosGetCombo } from '../../../services/basic-productos';
import ComboBox from '../../../componentes/Combos/ComboBox';
import ImageUpload from '../../../componentes/Combos/ImageUpload';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

const UpdateProductos = () => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    name: "", description: "", colors: "", fabrics: "", size: "",
    price: 0, discount: 0, quantity: 1, IdProductoBasico: 0,
  });

  const [productos, setProductos] = useState([]);
  const [validated, setValidated] = useState(false);

  const ApiProductoGet = async (input) => {
    const productoData = await productoGet(input);
    console.log(productoData);
    if (productoData && productoData.length > 0) {
      setFormData(productoData[0]);
      basicProductsGet(productoData[0].name_basic_products);
      console.log(formData);
    }
  };

  const basicProductsGet = async (input) => {
    try {
      const productosResponse = await productosGetCombo({ name: input, description: input });
      setProductos(productosResponse)
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    ApiProductoGet(id);
  }, [id]);

  const handleSubmit = async (e) => {
    setValidated(true);
    e.preventDefault();
    try {
      const productosResponse = await productosInsert(formData);
      if (productosResponse.status === 200) {
        Swal.fire({
          title: "¡Éxito!",
          text: "Producto basico añadido exitosamente",
          icon: "success",
        }).then(() => {
        });
      } else {
        Swal.fire({
          title: "¡Error!",
          text: productosResponse.message,
          icon: "error",
        }).then(() => {
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Container>
      <h4> Añadir producto </h4>
      <Form onSubmit={handleSubmit} noValidate validated={validated}>

        <Row className="mb-3">
          <Form.Group as={Col} className="mb-3" controlId="formName">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre de producto"
              name="name"
              required={true}
              value={formData.name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="formDescription">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              type="text"
              placeholder="Descripción"
              name="description"
              required={true}
              value={formData.description}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} className="mb-3" controlId="formPrice">
            <Form.Label>Precio</Form.Label>
            <div className="input-group">
              <span className="input-group-text">$MXN</span>
              <Form.Control
                type="number"
                placeholder="Precio $"
                name="price"
                step=".01" min="0"
                required={true}
                value={formData.price}
                onChange={handleChange}
              />
            </div>
          </Form.Group>

          <Form.Group as={Col} className="mb-3" controlId="formDiscount">
            <Form.Label>Descuento</Form.Label>
            <div className="input-group">
              <span className="input-group-text">$MXN</span>
              <Form.Control
                type="number"
                placeholder="Descuento $"
                name="discount"
                step=".01" min="0"
                required={true}
                value={formData.discount}
                onChange={handleChange}
              />
            </div>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="mb-3" controlId="formColors">
            <Form.Label>Colores</Form.Label>
            <Form.Control
              type="text"
              placeholder="Colores"
              required={true}
              name="colors"
              value={formData.colors}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="formFabrics">
            <Form.Label>Fabrics</Form.Label>
            <Form.Control
              type="text"
              placeholder="Fabrics"
              name="fabrics"
              required={true}
              value={formData.fabrics}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formTamanio">
            <Form.Label>Tamaño</Form.Label>
            <Form.Control
              type="text"
              placeholder="Tamaño"
              name="size"
              required={true}
              value={formData.size}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formQuantity">
            <Form.Label>Cantidad</Form.Label>
            <div className="input-group">
              <span className="input-group-text">No.</span>
              <Form.Control
                type="number"
                placeholder="Unidades:"
                name="quantity"
                step="1" min="1"
                required={true}
                value={formData.quantity}
                onChange={handleChange}
              />
            </div>
          </Form.Group>
        </Row>
        <Form.Group className="mb-3" controlId="formIdProductoBasico">
          <Form.Label>Producto basico</Form.Label>
          <ComboBox
            options={productos}
            name="IdProductoBasico"
            placeholder="Producto basico"
            required={true}
            onChange={handleChange}
            apicall={basicProductsGet}
          // defaultValue={formData.IdProductoBasico}
          />
        </Form.Group>

        <ImageUpload
          onChange={handleChange}
          name="imageUpload"
          required={true}
          multiple={true}
        />

        <Button variant="primary" type="submit">
          Añadir
        </Button>
      </Form>
    </Container>
  );
}

export default UpdateProductos;