import { Box } from '@mui/system';
import { Form, Button, Container } from 'react-bootstrap';
import { useState } from 'react';
import { productosInsert } from '../../../services/basic-productos';

import Swal from "sweetalert2";

const FormVentas = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const vendedorResponse = await productosInsert(formData);
      if (vendedorResponse.status === 200) {
        Swal.fire({
          title: "¡Éxito!",
          text: "Producto basico añadido exitosamente",
          icon: "success",
        }).then(() => {
        });
      } else {
        Swal.fire({
          title: "¡Error!",
          text: vendedorResponse.message,
          icon: "error",
        }).then(() => {
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Container>
      <h4> Añadir producto basico </h4>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formName">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nombre de producto"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formDescription">
          <Form.Label>Descripción</Form.Label>
          <Form.Control
            type="text"
            placeholder="Descripción"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Añadir
        </Button>
      </Form>
    </Container>
  );
}

export default FormVentas;