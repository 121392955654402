import { useContext } from "react";
import NotificationCountContext from "../hooks/NotificationCountContext";
import { API_URL, getHeaders } from "./config";

export async function addToCheckout(data) {
  const {
    postcode,
    street_name,
    number_address,
    state,
    city,
    paypal_request_id,
  } = data;
  const url = `${API_URL}/users/checkout/addCheckout`;
  const response = await fetch(url, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
  return await response.json();
}

export async function getCartCheckout() {
  const url = `${API_URL}/users/checkout/getCartCheckout`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}
