import React, { useState } from "react";
import NotificationCountContext from "./NotificationCountContext";
import { getCarritoNotificaciones } from "../services/carrito";

const NotificationCountProvider = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState(0);

  const updateNotificationCount = async () => {
    const response = await getCarritoNotificaciones();
    setNotificationCount(response[0].total_quantity);
  };

  return (
    <NotificationCountContext.Provider
      value={{ notificationCount, updateNotificationCount }}
    >
      {children}
    </NotificationCountContext.Provider>
  );
};

export default NotificationCountProvider;
