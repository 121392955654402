import { Link } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "../../Styles/LoginStyle.css";
import logo from "../../assets/images/LogoLogin.png";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import RegistroForm from "./RegistroForm";
import {
  forgotPassword,
  loginApi,
  refreshAccessToken,
} from "../../services/LoginService";
import { AccountContext } from "../../hooks/AccountContext";
import useSocketSetup from "../../useSocketSetup";

function LoginForm({ handleLoginSuccess }) {
  useSocketSetup();

  const [inputs, setInputs] = useState({});
  const navigate = useNavigate();
  //Modal Registrarse
  const [modalShow, setModalShow] = useState(false);
  const [numberSubmits, setNumberSubmits] = useState(0);
  const { handleSubmit } = useForm();
  //socket
  const { updateUser } = useContext(AccountContext);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const onSubmit = async () => {
    // console.log(inputs);
    setNumberSubmits(numberSubmits + 1);
    const loginResponse = await loginApi(inputs);
    if (loginResponse.status === 200) {
      Swal.fire({
        title: "¡Éxito!",
        text: loginResponse.message,
        icon: "success",
      }).then(() => {
        setInterval(refreshAccessToken, 1 * 60 * 1000);

        updateUser({
          accessToken: loginResponse.data.accessToken,
          refreshToken: loginResponse.data.refreshToken,
          userid: loginResponse.data.id,
          nombre: loginResponse.data.nombre,
          tipo: loginResponse.data.u_Type,
        });

        switch (loginResponse.data.u_Type) {
          case "admin":
            navigate("/admin");
            break;
          case "ventas":
            navigate("/ventas");
            break;
          case "consumidor":
            navigate("/");
            break;
          default:
            navigate("/");
        }
      });
    } else {
      if (numberSubmits > 1) {
        Swal.fire({
          icon: "info",
          title: "Oops...",
          text: "Parece que has intentado logear muchas veces sin exito, puedes crear una cuenta o recuperar tu contraseña a continuación.",
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: "Crear cuenta",
          denyButtonText: "Cambiar contraseña",
          denyButtonColor: "#7066e0",
        }).then((result) => {
          if (result.isConfirmed) {
            //Crear cuenta
            setModalShow(true);
          } else if (result.isDenied) {
            //Cambiar contraseña
            Swal.fire({
              title: "Cambio de contraseña",
              text: "Le enviaremos un link a su correo para continuar con el cambio de contraseña.",
              input: "email",
              inputLabel: "Tu correo",
              inputPlaceholder: "Introuduzca su correo",
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: async (email) => {
                const passResponse = await forgotPassword({ email });

                if (passResponse.status !== 200) {
                  Swal.showValidationMessage(
                    `Algo fallo: ${passResponse.message}`
                  );
                } else {
                  return passResponse;
                }
              },
            }).then((response) => {
              if (response.isConfirmed) {
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "Ya se ha enviado el correo de recuperación de contraseña",
                });
              }
            });
          }
        });
        setNumberSubmits(0);
      } else {
        Swal.fire({
          title: "¡Error!",
          text: loginResponse.message,
          icon: "error",
        }).then(() => {
          if (loginResponse.errors.status === 401) {
            // setError("correo", {
            //   type: "custom",
            //   message: "Usuario o contraseña incorrecto",
            // });
          }
        });
      }
    }
  };

  const onError = (errors) => {
    console.log(errors);
    Swal.fire({
      title: "...Ops",
      text: "Tienes algunos campos sin llenar",
      icon: "error",
    });
  };

  return (
    <>
      <div className="globalContainer">
        <div className="flexContainer">
          <div className="leftContainer">
            <div className="leftPad">
              <Link to="/">
                <img src={logo} alt="FortePiel Logo" />
              </Link>
              <h2>
                Unete a nuestra comunidad y forma parte de nuestra lista de
                clientes, realiza compras y contacta a nuestros vendedores. 
                Y recibe notificaciones de cada etapa de tu compra.
              </h2>
            </div>
          </div>
          <div className="rightContainer">
            <Form
              className="loginForm"
              onSubmit={handleSubmit(onSubmit, onError)}
              // action={process.env.URL_SERVER + "/Login"}
            >
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Dirección de correo</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Correo electrónico"
                  onChange={handleChange}
                  required
                />
                <Form.Text className="text-muted">
                  Nunca compartiremos tu correo electrónico con nadie.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              {/* <Button variant="primary" type="submit">
              Entrar
            </Button> */}
              <Button className="btnLogin" type="submit">
                Iniciar sesión
              </Button>
              <div className="separator"></div>
              <p className="text-center mt-2">
                ¿Primera vez aquí?{" "}
                <Link onClick={() => setModalShow(true)}>Crear cuenta</Link>
              </p>
            </Form>
          </div>
        </div>
      </div>

      <RegistroForm
        setModalShow={setModalShow}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default LoginForm;
