import styled from "styled-components";

export const Wrapper = styled.div`
  .sideContainer {
    width: 380px;
    margin-left: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
  }

  .pl-block-container {
    width: 100%;
  }

  .pl-text-container-pc {
    padding: 0 24px 16px;
    color: #999;
    font-size: 12px;
    text-align: center;
    background: #fff;
  }

  .pl-summary-container .pl-summary__item-title-pc {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .pl-summary__item-pc {
    display: flex;
    justify-content: space-between;
  }
`;
