import { API_URL, getHeaders } from "./config";

export async function consumidorPedidos(id) {
  const response = await fetch(API_URL + `/pedidos/getPedido/${id}`, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}

export async function consumidorAllPedidos(id) {
  const response = await fetch(API_URL + `/pedidos/getAllPedidos`, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}

export async function consumidorAllPedidosCategoria(estados) {
  const response = await fetch(
    API_URL + `/pedidos/getAllPedidosByEstados?estado=${estados}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  );
  return await response.json();
}

export async function getPedidosVendedor() {
  const response = await fetch(API_URL + `/pedidos/getPedidosVendedor`, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}

export async function getPedidosQuery({ status, cliente }) {
  if (!status && !cliente) return getPedidosVendedor();
  const response = await fetch(
    API_URL + `/pedidos/getPedidosQuery?status=${status}&cliente=${cliente}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  );
  return await response.json();
}

export async function getPedidosProduccion() {
  const response = await fetch(API_URL + `/produccion/getPedidosVendedor`, {
    method: "GET",
    headers: getHeaders(),
  });
  return await response.json();
}

export async function getPedidosQueryProduccion({ status, cliente }) {
  if (!status && !cliente) return getPedidosProduccion();
  const response = await fetch(
    API_URL + `/produccion/getPedidosQuery?status=${status}&cliente=${cliente}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  );
  return await response.json();
}

export async function updatePedidosEstado(data) {
  const response = await fetch(API_URL + `/pedidos/updatePedidosEstado`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
  return await response.json();
}
