import { Container, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { verifyEmail, refreshAccessToken } from "../../services/LoginService";
import { AccountContext } from "../../hooks/AccountContext";
import Swal from "sweetalert2";

export const EmailAutenticator = () => {
  const params = useParams();

  const navigate = useNavigate();

  const isMounted = useRef(true);

  const { updateUser } = useContext(AccountContext);

  const [tokenStatus, setTokenStatus] = useState({});

  useEffect(() => {
    if (isMounted.current) {
      const fetchData = async () => {
        const response = await verifyEmail(params.tokenEmail);

        console.log(response);

        if (response.status === 200) {
          Swal.fire({
            title: "¡Éxito!",
            text: "Cuenta verificada",
            icon: "success",
          }).then(() => {
            console.log("Exito");
            navigate("/");

            setInterval(refreshAccessToken, 1 * 60 * 1000);
            updateUser({
              accessToken: response.data.accessToken,
              refreshToken: response.data.refreshToken,
              userid: response.data.id,
              nombre: response.data.nombre,
              tipo: response.data.u_Type,
            });
            // setUser({
            //     accessToken: response.data.accessToken,
            //     refreshToken: response.data.refreshToken,
            //     userid: response.data.id,
            //     nombre: response.data.nombre,
            //     tipo: response.data.u_Type,
            // })
          });
        } else {
          Swal.fire({
            title: "¡Error!",
            text: response.status + "\n " + response.message,
            icon: "error",
          }).then(() => {
            navigate("/");

            setTokenStatus(response);
          });
        }
      };

      fetchData();
    }

    return () => {
      isMounted.current = false;
    };
  }, [params]);

  return (
    <Container
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">{tokenStatus.status}</Typography>
        <br />
        <Typography variant="h6">{tokenStatus.message}</Typography>
      </div>
    </Container>
  );
};
