import React, { useState, useEffect } from 'react';
import { Table, FormControl, Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { deleteVendedor, vendedorget } from '../../../services/vendedor';
import Swal from "sweetalert2";

function GridVentas(props) {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const response = await vendedorget(searchTerm);
            console.log(response)
            setData(response);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [searchTerm]);

    const traductorTypeUser = (user) => {
        if (user==="prod"){
            return "Producción"
        }else if(user==="ventas"){
            return "Ventas"
        }
    }

    const handleChange = (e) => {
        console.log(e.target.value);
        setSearchTerm(e.target.value);
    };

    const filteredData = data.filter((item) =>
        item.first_Name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleEdit = (id) => {
        navigate(`../updateVentas/${id}`);
    }

    const handleDelete = async (id) => {
        const response = await deleteVendedor(id);

        if (response.status !== 200) {
            Swal.fire({
                title: "¡Error!",
                text: response.message,
                icon: "error",
            }).then(() => {
                return
            });
        } else {
            Swal.fire({
                title: "Exito",
                text: response.message,
                icon: "success",
            }).then(() => {
                fetchData();
                return
            });
        }
    }

    return (
        <Container>
            <FormControl
                type="text"
                placeholder="Buscar por nombre"
                className="mb-3"
                value={searchTerm}
                onChange={handleChange}
            />
            <Table striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Apellidos</th>
                        <th>Correo</th>
                        <th>Tipo usuario</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.first_Name}</td>
                            <td>{item.last_Name}</td>
                            <td>{item.email}</td>
                            <td>{traductorTypeUser(item.u_Type)}</td>
                            <td>
                                <Button
                                    style={{ margin: "0px 15px" }}
                                    variant="warning"
                                    onClick={() => handleEdit(item.id)}
                                >
                                    Modificar
                                </Button>
                                <Button
                                    style={{ margin: "0px 15px" }}
                                    variant='danger'
                                    onClick={() => handleDelete(item.id)}
                                >
                                    Eliminar
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
}

export default GridVentas;