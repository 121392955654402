import { Alert, Button, Container, Grid, Snackbar, ThemeProvider, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NotificationCountContext from '../../hooks/NotificationCountContext';
import { ClienteSingleProductoGet } from '../../services/basic-productos';
import { addToCarrito } from '../../services/carrito';
import { ColorModeContext, useMode } from "../theme";
import styled from 'styled-components';

export const ProductoIndividual = () => {
    const params = useParams();

    const [dataProduct, setDataProduct] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState({
        status: false,
        message: "",
    });
    const [theme, colorMode] = useMode();

    const navigate = useNavigate();
    const { updateNotificationCount } = useContext(NotificationCountContext);
    const handleOpen = async () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const addToCar = async () => {
        const user_id = localStorage.getItem("userid");
        if (!user_id) {
            navigate("/login");
            return;
        }
        try {
            const response = await addToCarrito({
                product_id: dataProduct.id,
                quantity: 1,
                user_id,
            }).then((res) => {
                updateNotificationCount();
                return res;
            });
            setMessage({ status: true, message: response.message });
            handleOpen();
        } catch (error) {
            setMessage({
                status: false,
                message: "`Error al agregar el producto al carrito`",
            });
            handleOpen();
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ClienteSingleProductoGet(params);
                setDataProduct(response);
                console.log(response[0]);

                let tempData = {
                    id: response[0].id,
                    name: response[0].name,
                    description: response[0].description,
                    categoria: response[0].name_basic_products,
                    color: response[0].colors,
                    fabrics: response[0].fabrics,
                    size: response[0].size,
                    unit_Price: parseFloat(response[0].unit_Price).toFixed(2),
                    discount: response[0].discount,
                    quantity: response[0].quantity,
                    imgPath: response[0].path_img,
                    porcentajeDes: parseFloat((response[0].discount) * 100 / response[0].unit_Price).toFixed(2),
                    priceWDisc: parseFloat(response[0].unit_Price - response[0].discount).toFixed(2)
                };

                setDataProduct(tempData);
                console.log("debug");
                console.log(dataProduct);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [params]);

    const ImageContainer = styled.div`
        position: relative;
        overflow: hidden;
        max-width: 550px;
        height= 350;
    `;
    
    const ZoomImage = styled.img`
        transition: transform 0.3s ease-in-out;
        transform-origin: top left;
    `;

    const [zoom, setZoom] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseEnter = () => {
        setZoom(true);
    };
    
    const handleMouseLeave = () => {
        setZoom(false);
        setPosition({ x: 0, y: 0 });
    };
    
    const handleMouseMove = (event) => {
        const { left, top, width, height } = event.target.getBoundingClientRect();
        const mouseX = event.clientX - left;
        const mouseY = event.clientY - top;
        const xPos = Math.min((mouseX / width) * 100, 100); // Limitar la posición x al 100%
        setPosition({ x: xPos, y: (mouseY / height) * 100 });
    };

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <Container sx={{ marginTop: "60px" }}>
                    <Grid container sx={{ mb: 5 }} spacing={2}>
                        <Grid item sx={{ marginTop: "-20px", marginX: "50px", maxHeight:"550px", }} sm="6">
                            <img 
                                style={{maxWidth:"550px",maxHeight:"550px",}}
                                src={
                                (dataProduct.imgPath)
                                    ? dataProduct.imgPath
                                    : "https://dummyimage.com/250x250/fff/aaa"
                            } alt={dataProduct.name} 
                            />
                        </Grid>
                        <Grid item sm="4" >
                            <Typography
                                variant='h2'
                                sx={{ fontWeight: "bold" }}
                            >{dataProduct.name}</Typography>
                        
                        <div style={{display:"flex"}}>
                            {
                                dataProduct.discount <= 0 ? (
                                    <></>
                                )

                                    : (
                                        <>
                                            <Typography
                                            marginTop="15px"
                                            variant='h4'
                                            sx={{ color: "#ff6d00" }}
                                            > 
                                                <del>{"MXN $"+ dataProduct.unit_Price} </del> 
                                            </Typography>
                                            
                                            <Typography
                                                marginTop="15px"
                                                marginLeft="5px"
                                                variant='h5'
                                                sx={{ color: "#ff6d00" }}
                                            >
                                                {"" + dataProduct.porcentajeDes + "%"}
                                            </Typography>
                                        </>
                                    )
                            }
                        </div>

                            <Typography
                                marginTop="15px"
                                variant='h3'
                                sx={{
                                    fontWeight: "bold",
                                    color: "#ff6d00"
                                }}
                            >{"MXN $" + (dataProduct.priceWDisc)}</Typography>

                            <Typography
                                marginTop="15px"
                                variant='h5'

                            >{dataProduct.description}</Typography>

                            {
                                ((dataProduct.fabrics) === "") || (dataProduct.fabrics === null) ? (
                                    <></>
                                ) : (
                                    <Typography
                                        marginTop="15px"
                                        variant='h5'

                                    >{"Material: " + dataProduct.fabrics}</Typography>
                                )
                            }

                            <Typography
                                marginTop="15px"
                                variant='h5'

                            >{"Color: " + dataProduct.color}</Typography>

                            {
                                ((dataProduct.size) === "") || (dataProduct.size === null) ? (
                                    <></>
                                ) : (
                                    <Typography
                                        marginTop="15px"
                                        variant='h5'

                                    >{"Talla: " + dataProduct.size}</Typography>
                                )
                            }

                            {
                                (dataProduct.quantity) === 0 ? (
                                    <Typography
                                        marginTop="15px"
                                        variant='h5'

                                    >{"No hay disponibles"}</Typography>
                                ) : (
                                    <Typography
                                        marginTop="15px"
                                        variant='h5'

                                    >{"Disponibles: " + dataProduct.quantity}</Typography>
                                )
                            }

                            <Button sx={{ marginTop: "15px" }} onClick={addToCar} variant="outlined">
                                <Typography
                                    variant='h5'
                                >Agregar al carrito</Typography>
                            </Button>

                            <Snackbar
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                open={open}
                                autoHideDuration={3000}
                                onClose={handleClose}
                            >
                                <Alert
                                    onClose={handleClose}
                                    severity={message ? "success" : "error"}
                                    sx={{ width: "100%" }}
                                >
                                    {message.message}
                                </Alert>
                            </Snackbar>

                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}