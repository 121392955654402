import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
import Iconify from "./iconify";
import AccessibleForwardIcon from "@mui/icons-material/AccessibleForward";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import PaidIcon from "@mui/icons-material/Paid";
import { fShortenNumber } from "./formatNumber";
import {FormatPrice} from "../../../../src/componentes/FormatPrice/FormatPrice";
import theme from "../../../componentes/oldHeader/headerTheme";

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.elementType, // Update prop type to elementType
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({
  title,
  total,
  icon: Icon,
  simbolo,
  color = "primary",
  sx,
  ...other
}) {
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: "center",
        color: (theme) => theme.palette[color].darker,
        // bgcolor: (theme) => theme.palette[color].background,
        bgcolor: "#444",
        ...sx,
      }}
      {...other}
    >
      <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].dark,
          // backgroundImage: (theme) =>
          //   ` linear-gradient(135deg, ${alpha(
          //     theme.palette[color].dark,
          //     0
          //   )} 0%, ${alpha(theme.palette[color].light, 0.24)} 100%)`,
          backgroundColor: "#ddd",
        }}
      >
        {title === "Ordenes hechas" || title === "Ordenes (semana)" ? (
          <ShoppingCartIcon sx={{ width: 20, height: 20 }} />
        ) : title === "Unidades vendidas" ||
          title === "Unidades vendidas (semana)" ? (
          <ShoppingBasketIcon sx={{ width: 20, height: 20 }} />
        ) : (
          <PaidIcon sx={{ width: 20, height: 20 }} />
        )}
      </StyledIcon>

      <Typography variant="h3">
        {/* {fShortenNumber(total)}  */}
        {
          (title==="Ganancias"||title==="Ganancias (semana)")
          ?
            <>
              MXN $ <FormatPrice price={total}/> {simbolo}
            </>
          :<>{total} {simbolo}</>
        }
      </Typography>

      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
}
