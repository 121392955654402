import React from 'react'
import {Typography, Container} from '@mui/material'

export const TermsAndConditions = () => {
    return (
        <Container>
            <Typography variant="h6">
                <Typography variant="h4">
                    <p>Términos y Condiciones de Uso</p>
                    <p>Bienvenid@ a nuestro sitio web/servicio. Antes de utilizar este sitio web/servicio, te pedimos que leas detenidamente los siguientes términos y condiciones. Al acceder o utilizar este sitio web/servicio, aceptas estar legalmente vinculado/a por estos términos y condiciones. Si no estás de acuerdo con alguno de estos términos, no utilices este sitio web/servicio.</p>
                </Typography>
                
                <p><Typography variant='h5'>Uso del Sitio Web/Servicio </Typography>
                    <li>1.1. Este sitio web/servicio y su contenido son propiedad de Fortepiel y están protegidos por las leyes de propiedad intelectual aplicables. Queda prohibida cualquier reproducción, distribución o modificación del contenido, a menos que se cuente con la autorización expresa por escrito de Fortepiel. </li>
                    <li>1.2. El uso de este sitio web/servicio está permitido únicamente para fines legítimos y no puede ser utilizado con fines ilegales o no autorizados. Al utilizar este sitio web/servicio, te comprometes a cumplir con todas las leyes y regulaciones aplicables.</li>
                </p>

                <p><Typography variant='h5'>Información y Contenido </Typography>
                    <li>2.1. La información proporcionada en este sitio web/servicio tiene fines informativos únicamente y no constituye asesoramiento profesional o recomendaciones. Fortepiel no se responsabiliza por la exactitud, integridad o actualidad de la información proporcionada. </li>
                    <li>2.2. El contenido generado por los usuarios (comentarios, publicaciones, etc.) es responsabilidad exclusiva de quienes lo crean. Fortepiel no respalda ni se responsabiliza por el contenido generado por los usuarios.</li>
                </p>
                
                <p><Typography variant='h5'>Privacidad y Protección de Datos </Typography>
                    <li>3.1. Solo hacemos uso de tus datos personales con el fin de analizar el uso de la página, estos no serán vendidos ni usados por un tercero. </li>
                    <li>3.2. Al utilizar este sitio web/servicio, aceptas el uso de cookies, estas solo son utilizadas para guardar información de su usuario y aumentar su experiencia comprando, bajo ningún momento lo utilizaremos con el fin de dañarlo a usted o a su dispositivo.</li>
                </p>

                <p><Typography variant='h5'>Enlaces a Sitios de Terceros </Typography>
                    <li>4.1. Este sitio web/servicio puede contener enlaces a sitios web de terceros. Estos enlaces son proporcionados únicamente para tu conveniencia. Fortepiel no ejerce control sobre el contenido o las políticas de privacidad de sitios web de terceros y no se responsabiliza por ellos.</li>
                </p>

                <p><Typography variant='h5'>Limitación de Responsabilidad </Typography>
                    <li>5.1. Fortepiel no será responsable por daños directos, indirectos, incidentales, especiales, consecuentes o ejemplares que resulten del uso o la imposibilidad de uso de este sitio web/servicio. </li>
                    <li>5.2. Fortepiel no garantiza que este sitio web/servicio esté libre de errores, virus o cualquier otro componente dañino.</li>
                </p>

                <p><Typography variant='h5'>Modificaciones a los Términos y Condiciones </Typography>
                    <li>6.1. Fortepiel se reserva el derecho de modificar estos términos y condiciones en cualquier momento. Las modificaciones entrarán en vigencia una vez publicadas en este sitio web/servicio. Te recomendamos revisar periódicamente estos términos y condiciones para estar al tanto de las actualizaciones.</li>
                </p>
                
                <p><Typography variant='h5'>Ley Aplicable y Jurisdicción </Typography>
                    <li>7.1. Estos términos y condiciones se regirán e interpretarán de acuerdo con las leyes de México, sin consideración a sus conflictos de principios legales. Cualquier disputa que surja en relación con este sitio web/servicio estará sujeta a la jurisdicción exclusiva de los tribunales de México.</li>
                </p>

                <p>
                    Al utilizar este sitio web/servicio, reconoces que has leído, comprendido y aceptado estos términos y condiciones en su totalidad. Si tienes alguna pregunta o inquietud, por favor contáctanos a través de marcos@chamarrasforte.com.mx.
                </p>
            </Typography>
        </Container>
    )
}
