import React from 'react'
import { OfficeMapLoc } from '../../componentes/locationMap/OfficeMapLoc'
import { Form, Button, FloatingLabel } from 'react-bootstrap'
import Swal from "sweetalert2";
import { Divider, Grid, Paper, Typography, Container } from '@mui/material'

export const Contactanos = () => {

    const mensajeContactanos = (e) => {
        e.preventDefault();

        const formData = {
            nombre: e.target.elements.nombre.value,
            email: e.target.elements.email.value,
            mensaje: e.target.elements.mensaje.value,
            celular: e.target.elements.celular.value,
        }

        if ((!formData.email||(formData.email===""))&&(!formData.celular||(formData.celular===""))){
            Swal.fire({
                title: "Error",
                text: "Debes meter un correo o un telefono",
                icon: "error",
            });
            return
        }

        const message = "Esta persona tiene algo que comentarte\n"+
            "\nNombre:"+formData.nombre+
            ((formData.Correo)?"\nCorreo: "+formData.email:"")+
            ((formData.celular)?"\nCelular: "+formData.celular:"")+
            "\nMensaje: "+formData.mensaje+"\n";

        console.log(message);

        const encodedMessage = encodeURIComponent(message);

        window.location.href=`mailto:marcos@chamarrasforte.com.mx?subject=Contacto pagina web de ${formData.nombre}&body=${encodedMessage}`
    }

    return (
        <Container style={{marginBottom:"3%"}}>
            <Typography variant='h4' style={{margin:"2% 0"}}> Contáctanos </Typography>
            
            <Grid style={{backgroundColor:"#2ea9e0", display:"flex", justifyContent:"center", alignItems:"center", borderRadius:"20px"}} container spacing={3.5}>
                <Grid item xs={12} sm={4.5} style={{height:"35rem", flexGrow:"1", padding:"20px 10px"}}>
                    <Paper>
                        <OfficeMapLoc heightCom={32.5}/>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={7} style={{height:"35rem", flexGrow:"1", padding:"20px 10px"}}>
                <Form onSubmit={e=>mensajeContactanos(e)}>
                    <Form.Group className='mb-3' controlId='name'>

                        <Form.Text>
                            <Typography variant='h5' color="white"><p>Envíanos un mensaje</p></Typography>
                        </Form.Text>

                        <FloatingLabel
                            controlId='floatingInput'
                            label='Nombre'
                        >

                            <Form.Control 
                                name='nombre'
                                type="text" 
                                placeholder='Introduzca su nombre completo' 
                                style={{marginBottom:"3%"}}
                            />

                        </FloatingLabel>

                        <FloatingLabel
                            controlId='floatingInput' 
                            label='E-mail'
                            className='mb-3'
                        >
                            <Form.Control 
                                name='email'
                                type='email'
                                placeholder='Introduzca su E-mail'
                            />
                        </FloatingLabel>

                        <FloatingLabel
                            controlId='floatingInput' 
                            label='Celular'
                            className='mb-3'
                        >
                            <Form.Control 
                                name='celular'
                                type='celular'
                                placeholder='Introduzca su E-mail'
                            />
                        </FloatingLabel>

                        <FloatingLabel
                            controlId='floatingInput'
                            label='Mensaje'
                            className='mb-3'
                        >
                            <Form.Control 
                                name='mensaje'
                                as="textarea" 
                                style={{marginBottom:"3%", height:"9rem"}} 
                                type="text" 
                                placeholder='Te escuchamos'
                            />
                        </FloatingLabel>

                        <Divider/>

                        <Form.Text>
                            <Typography variant='h6' color="white"><p>No compartiremos tu información con terceros</p></Typography>
                        </Form.Text>

                        <Button variant="primary" style={{backgroundColor:'#454545', border:'#454545' }} type="submit">
                            Enviar
                        </Button>
                    </Form.Group>
                </Form>
                </Grid>               
            </Grid>
        </Container>
    )
}