import React, { useState, useEffect } from "react";

import ListaProductos from "../../componentes/ListaProductos/ListaProductos";
import { Container } from "@mui/system";
import "../../Styles/Products.css";
import {
  ClienteCategoriasGetAll,
  ClienteProductosGetByCategoria,
} from "../../services/basic-productos";
import { Link } from "@mui/material";

export const Productos = () => {
  const [products, setProducts] = useState([]);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState([]);
  const [categorias, setCategorias] = useState([]);

  const fetchCategorias = async () => {
    try {
      const response = await ClienteCategoriasGetAll();
      let trueResponse=[];

      response.map((categoria)=>{
        if (categoria.numberProducts>0){
          trueResponse.push(categoria);
        }
      })

      setCategorias(trueResponse);
      setCategoriaSeleccionada(trueResponse[0].id);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchData = async () => {
    try {
      const response = await ClienteProductosGetByCategoria(
        categoriaSeleccionada
      );
      setProducts(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCategoria = (idCategoria) => {
    setCategoriaSeleccionada(idCategoria);
  };

  useEffect(() => {
    fetchCategorias();
  }, []);

  useEffect(() => {
    fetchData();
  }, [categoriaSeleccionada]);

  return (
    <>
      <Container>
        <br></br>
        {/* <h1>Productos</h1> */}
        {/* <div className="product-tabs-menu w-tab-menu"> */}
        <div className="product-tabs-menu w-tab-menu">
          {categorias.map((categoria, index) => (
            <Link
              style={{textDecoration:"none"}}
              className={`product-tab-link ${
                categoriaSeleccionada === categoria.id ? "selected" : ""
              }`}
              key={index}
              onClick={(e) => {
                handleCategoria(categoria.id);
              }}
            >
              {categoria.name}
            </Link>
          ))}
        </div>
        <ListaProductos products={products} />
      </Container>
    </>
  );
};
