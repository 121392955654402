import styled from "styled-components";

export const Wrapper = styled.div`
  .wrapForm {
    display: flex !important;
    justify-content: center;
    width: 95%;
    margin: auto;
  }

  .wrapText {
    width: 100%;
  }
`;
