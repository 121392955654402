import { BrowserRouter } from "react-router-dom";
import PrimarySearchAppBar from "../../componentes/oldHeader/header";
import { MainRouter } from "../../routes/MainRouter";
import NotificationCountProvider from "../../hooks/NotificationCountProvider";
import { MainFooter } from "../../componentes/footer/Mainfooter";
import { useState } from "react";
//import NavBar from "../componentes/Header/Header";

const UserIndex = () => {

  return (
    <BrowserRouter>
      <NotificationCountProvider>
        <PrimarySearchAppBar/>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <MainRouter/>
          </div>
        </div>
      </NotificationCountProvider>
    </BrowserRouter>
  );
};

export default UserIndex;
