import { ColorModeContext, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

import Sidebar from "../../admin/components/global/Sizebar";
import { ProSidebarProvider } from "react-pro-sidebar";
import { useState } from "react";
import "../../admin/index.css";
import { AdminRouter } from "../../routes/AdminRouter";
import { Navigate, useNavigate } from "react-router-dom";

const AdminPage = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const navigate = useNavigate();

  // if (!isLoggedIn) {
  //   return <Navigate to="/login" />;
  // }

  // if (props.role && role !== props.role) {
  //   return <Navigate to="/not-authorized" />;
  // }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app" style={{ minHeight: "100vh", display: "flex" }}>
          <ProSidebarProvider>
            <Sidebar isSidebar={isSidebar} />
          </ProSidebarProvider>
          <main className="content" style={{ flexGrow: 1 }}>
            {/* <Topbar setIsSidebar={setIsSidebar} /> */}
            <br />
            <AdminRouter />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default AdminPage;
