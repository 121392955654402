import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Form } from "react-bootstrap";

const ComboBox = ({ options, onChange, name, apicall, ...rest }) => {
  const [selectedValue, setSelectedValue] = useState(options && options.length ? options[0] : {});
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setIsOpen(true);
    apicall(event.target.value);
  };

  const handleValueSelect = (newValue) => {
    setSelectedValue(newValue);
    setInputValue(newValue.value);
    setIsOpen(false);
    onChange({ target: { name: name, value: newValue.id } })
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const inputValueObject = options.find(
        (option) => option.value === inputValue
      );
      if (inputValueObject) {
        setSelectedValue(inputValueObject);
        onChange(inputValueObject);
      } else {
        handleValueSelect(options[0]);
      }
      setIsOpen(false);
    } else if (event.key === "Escape") {
      setInputValue(selectedValue.value);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setInputValue(selectedValue.value);
  }, [selectedValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, dropdownRef]);

  const filteredValues = options && options.length && inputValue ? options.filter((option) =>
    option.value.toLowerCase().includes(inputValue.toLowerCase())
  ) : options;

  return (
    <div style={{ position: "relative" }}>
      <Form.Control
        type="text"
        value={inputValue}
        autocomplete="off"
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        onClick={() => setIsOpen(true)}

        {...rest}
      />

      <Dropdown.Menu show={isOpen} ref={dropdownRef}>
        {filteredValues.map((option) => (
          <Dropdown.Item
            key={option.id}
            onClick={() => handleValueSelect(option)}
            active={option.id === selectedValue.id}
          >
            {option.value}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </div>
  );
};

export default ComboBox;
