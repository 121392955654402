import { API_URL, getHeaders } from "./config";

export async function loginApi(data) {
  const response = await fetch(API_URL + `/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return await response.json();
}

export async function getAllUsers() {
  try {
    const response = await fetch(API_URL + "/login");
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function createUser(data) {
  const response = await fetch(API_URL + `/login/registrarse`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return await response.json();
}

export async function verifyEmail(tokenEmail){
  const response = await fetch(API_URL + `/login/verificarCorreo/${tokenEmail}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  );
  return await response.json();
}

export async function forgotPassword(email){
  const response = await fetch(API_URL + `/login/olvideContrasena`, 
  {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(email),
  })
  return await response.json();
}

export async function verifyPasswordToken(tokenEmail){
  const response = await fetch(API_URL + `/login/verificarCambiarContrasena/${tokenEmail}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  );
  return await response.json();
}

export async function changePassword(data){
  const response = await fetch(API_URL + `/login/cambiarContrasena`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return await response.json();
}

export async function refreshAccessToken() {
  const tokens = {
    id: localStorage.getItem("userid"),
    token: localStorage.getItem("refreshToken"),
  };
  // Make a request to your refresh token API to get a new access token
  const tokenResponse = await refreshToken(tokens);
  // Store the new access token and refresh token in local storage
  localStorage.setItem("accessToken", tokenResponse.data.accessToken);
  localStorage.setItem("refreshToken", tokenResponse.data.refreshToken);
  localStorage.setItem("userid", tokenResponse.data.id);
  localStorage.setItem("nombre", tokenResponse.data.nombre);
}

export async function refreshToken(data) {
  const response = await fetch(API_URL + `/login/refreshTokens`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return await response.json();
}

export async function logOut() {
  const tokens = {
    id: localStorage.getItem("userid"),
    token: localStorage.getItem("refreshToken"),
  };

  const response = await fetch(API_URL + `/api/login/logout`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(tokens),
  });

  localStorage.clear();
  return await response.json();
}
