import React, { useState, useEffect } from "react";

import ListaProductos from "../../componentes/ListaProductos/ListaProductos";
import { Container } from "@mui/system";
import "../../Styles/Products.css";
import { hotsalesAll } from "../../services/basic-productos";
import { Link, Typography } from "@mui/material";

export const Hotsales = () => {
  const [products, setProducts] = useState([]);

  const fetchData = async () => {
    try {
      const response = await hotsalesAll();
      setProducts(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {products.length > 0 ? (
        <>
          <Typography variant="h4" className="titleSection">
            ¡Te puede interesar!
          </Typography>
          <ListaProductos products={products} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
