import styled from "styled-components";

export const Wrapper = styled.div`
  .component-container {
    margin-top: 2rem;
    color: white;
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }

  .history-box {
    background-color: #3f51b5;
    text-align: center;
    margin-bottom: 2rem;
    padding: 1.5rem;
  }

  .section-title {
    background-color: #fe9e0d;
    box-shadow: 0.3rem 0.3rem black;
  }

  .grid-container {
    display: flex;
    flex-flow: row;
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }

  .grid-item {
    flex-grow: 1;
  }

  .mission-box,
  .values-box {
    background-color: #3f51b5;
    text-align: center;
    margin-bottom: 2rem;
    display: flex;
    padding: 1.5rem;
    min-height: 450px;
  }

  .list {
    color: white;
    font-weight: 400;
  }

  .divider {
    background-color: black;
  }

  .contact-box {
    text-align: center;
    margin-top: 2rem;
  }

  .classes_root {
    padding: theme.spacing(3);
    marginbottom: theme.spacing(3);
  }
  .classes_title {
    marginbottom: theme.spacing(2);
  }
  .classes_text {
    marginbottom: theme.spacing(3);
  }
  .classes_image {
    width: 100%;
    height: 100%;
    objectfit: cover;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .classes_image_banner {
    width: 100%;
    height: 100%;
    objectfit: cover;
    margin-bottom: 10px;
    display: block;
  }

  .dark-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
  }

  .image-container{
    position: relative;
  }

  .text-overlay{
    position: absolute;
    top:50%;
    left:50%;
    transform: trasnlate(-50%,-50%);
    background-color: rgba(0,0,0,0.5);
    padding: 20px;
    color: #fff;
  }
`;
