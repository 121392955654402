import styled from "styled-components";

export const Wrapper = styled.div`
  .paper {
    //width: 80vw;
    height: 80vh;
    //max-width: 500px;
    max-height: 700px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  .paper2 {
    width: 80vw;
    max-width: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  .container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 75%;
  }

  .messagesBody {
    background: #e3e3e3;
    width: calc(100% - 20px);
    margin: 10px;
    overflow-y: scroll;
    height: calc(100% - 80px);
  }
`;
