import { ColorModeContext, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

// import Sidebar from "../../prod/components/global/Sizebar";
import Sidebar from "../../prod/components/global/Sizebar";
import { ProSidebarProvider } from "react-pro-sidebar";
import { useState } from "react";
import "../../admin/index.css";
// import { AdminRouter } from "../../routes/AdminRouter";
import { ProdRouter } from "../../routes/ProcRoute";
import { Navigate, useNavigate } from "react-router-dom";

const ProcPage = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const navigate = useNavigate();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app" style={{ minHeight: "100vh", display: "flex" }}>
          <ProSidebarProvider>
            <Sidebar isSidebar={isSidebar} />
          </ProSidebarProvider>
          <main className="content" style={{ flexGrow: 1 }}>
            <br />
            <ProdRouter />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default ProcPage;
