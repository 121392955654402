import React, { useState, useEffect } from 'react';
import { Table, FormControl, Container, Button } from 'react-bootstrap';

import { productosGet } from '../../../services/basic-productos';

function GridVentas(props) {
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    console.log("useEffect")
    const fetchData = async () => {
      try {
        const response = await productosGet({ name, description });
        console.log(response)
        setData(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [name, description]);

  const handleNameChange = (e) => {
    console.log(e.target.value);
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    console.log(e.target.value);
    setDescription(e.target.value);
  };

  const handleClear = () => {
    setName("");
    setDescription("");
  };

  const handleEdit = (id) => {
    console.log(`Editar el id : ${id}`);
    // Add logic for handling the edit functionality here
  }

  return (
    <Container>
      <div className="d-flex align-items-center">
        <FormControl
          type="text"
          placeholder="Nombre"
          className="mr-3"
          value={name}
          onChange={handleNameChange}
        />
        <FormControl
          type="text"
          placeholder="Descripción"
          className="mr-3"
          value={description}
          onChange={handleDescriptionChange}
        />
        <Button variant="secondary" onClick={handleClear}>Limpiar</Button>
      </div>
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Descripción</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.description}</td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => handleEdit(item.id)}
                >
                  Modificar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default GridVentas;