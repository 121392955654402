import { Alert, Container, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Cart from "./cart/cart";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import NotificationCountContext from "../../hooks/NotificationCountContext";
import { addToCarrito } from "../../services/carrito";
import { ClienteProductosGet } from "../../services/basic-productos";

const Carrito = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({
    status: false,
    message: "",
  });
  const [products, setProducts] = useState([]);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const { updateNotificationCount } = useContext(NotificationCountContext);

  const calculateTotal = (items) =>
    items.reduce(
      (acc, item) => acc + item.quantity * (item.unit_price - item.discount),
      0
    );

  const fetchData = async () => {
    try {
      const response = await ClienteProductosGet();
      setProducts(response);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const addToCart = async (item) => {
    const user_id = localStorage.getItem("userid");
    try {
      const response = await addToCarrito({
        product_id: item.id,
        quantity: 1,
        user_id,
      }).then((res) => {
        updateNotificationCount();
        return res;
      });
      setMessage({ status: true, message: response.message });
      fetchData();
      handleOpen();
    } catch (error) {
      setMessage({
        status: false,
        message: "`Error al agregar el producto al carrito`",
      });
      handleOpen();
    }
  };
  const removeFromCart = async (item) => {
    const user_id = localStorage.getItem("userid");
    try {
      const response = await addToCarrito({
        product_id: item.id,
        quantity: -1,
        user_id,
      }).then((res) => {
        updateNotificationCount();
        return res;
      });
      setMessage({ status: true, message: "Articulo eliminado" });
      fetchData();
      handleOpen();
    } catch (error) {
      setMessage({
        status: false,
        message: "`Error al agregar el producto al carrito`",
      });
      handleOpen();
    }
  };

  let titulo = "";
  if (products.length) {
    titulo = "Carrito";
  } else {
    titulo = "Tu carrito está vacío.";
  }

  return (
    <>
      <Container>
        <Typography variant="h3" style={{ margin: "15px" }}>
          {titulo}
        </Typography>
        <Typography variant="h4" style={{ marginBottom: "25px" }}>
          Subtotal ({products.length} productos):
        </Typography>
        <Cart
          key={"Cart"}
          cartItems={products}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
        />
        {products.length > 0 ? (
          <Typography
            variant="h3"
            align="right"
            style={{ paddingBottom: "25px" }}
          >
            Total: MXN ${calculateTotal(products).toFixed(2)}
          </Typography>
        ) : (
          <></>
        )}
        <div className="d-grid gap-2" style={{ paddingBottom: "25px" }}>
          {products.length > 0 ? (
            <Button
              variant="warning"
              size="lg"
              onClick={() => {
                navigate("/consumidor/checkout");
              }}
            >
              Comprar
            </Button>
          ) : (
            <Button
              variant="warning"
              size="lg"
              onClick={() => {
                navigate("/productos");
              }}
            >
              Seguir comprando
            </Button>
          )}
        </div>
      </Container>
      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={message ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {message.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Carrito;
