import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "./Card";
import { productoPromosGet } from "../../../services/productos";
import { useEffect, useState } from "react";

function GridCards() {
  const [products, setProducts] = useState([]);
  const [numProducts, setNumProducts] = useState(4);

  useEffect(() => {
    console.log("useEffect");
    const fetchData = async () => {
      try {
        const response = await productoPromosGet(numProducts);
        setProducts(response);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [numProducts]);

  return (
    <div className="get-discount-item-wrap">
      {products.map((product) => (
        <Card product={product}></Card>
      ))}
      {/* <Col>
        </Col> */}
    </div>
  );
}

export default GridCards;
