import { ColorModeContext, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ConsumidorRouter } from "../../routes/ConsumidorRouter";

const ConsumidorPage = () => {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      {/* <ThemeProvider theme={theme}> */}
      <CssBaseline />
      <ConsumidorRouter />
      {/* </ThemeProvider> */}
    </ColorModeContext.Provider>
  );
};

export default ConsumidorPage;
