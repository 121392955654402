import { useContext } from "react";
import NotificationCountContext from "../hooks/NotificationCountContext";
import { API_URL, getHeaders } from "./config";

export async function getDomicilio(data) {
  const { value } = data;
  let query = "";
  const url = `https://nominatim.openstreetmap.org/search?`;
  query += "postalcode=" + value;
  // query += "&country=mexico";
  query += "&format=json&addressdetails=1&limit=5&polygon=1&countrycodes=MX";

  const response = await fetch(url + query, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
  return await response.json();
}
