import { useContext } from "react";
import NotificationCountContext from "../hooks/NotificationCountContext";
import { API_URL, getHeaders } from "./config";

export async function getAllConversation() {
  const url = `${API_URL}/users/chat/getAllConversation`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  const data = await response.json();
  return data;
}

export async function addMessageToConversation({
  id_conversation,
  id_sender,
  message,
}) {
  const url = `${API_URL}/users/chat/addMessageToConversation`;
  const response = await fetch(url, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ id_conversation, id_sender, message }),
  });
  const data = await response.json();
  return data;
}

export async function getConversation(conversationId) {
  const url = `${API_URL}/users/chat/${conversationId}/messages`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  const data = await response.json();
  return data;
}

export async function messagesAlert() {
  const url = `${API_URL}/users/chat/messagesAlert`;
  const response = await fetch(url, {
    method: "GET",
    headers: getHeaders(),
  });
  const data = await response.json();
  return data;
}
