import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import ComboDomicilio from "./comboDomicilio";
import Select from "react-select";
import { estados } from "./estados.ts";
import { municipios } from "./municipios.ts";
import { Typography } from "@mui/material";
import { addToCheckout } from "../../services/checkout";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

function CheckoutForm({ calculateTotal, setCustomerInfo }) {
  const [validated, setValidated] = useState(false);
  const [numberAddress, setNumberAddress] = useState("");
  const [streetName, setStreetName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [domicilio, setDomicilio] = useState("");

  const navegate = useNavigate();

  const transformedMunicipios = Object.keys(municipios).reduce((acc, key) => {
    const uppercaseKey = key.toUpperCase();
    acc[uppercaseKey] = municipios[key];
    return acc;
  }, {});
  const cityOptions = state
    ? transformedMunicipios[state.value.toUpperCase()].map((city) => ({
        value: city.toUpperCase(),
        label: city.toUpperCase(),
      }))
    : [];
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity()) {
      console.log("Form is valid");
      setValidated(false);
      setCustomerInfo({
        validated: true,
        orderData: {
          postcode: domicilio.address.postcode,
          street_name: streetName,
          number_address: numberAddress,
          state: state.value,
          city: city.value,
        },
      });
    } else {
      console.log("Form is not valid");
      setValidated(true);
      setCustomerInfo({
        validated: false,
        orderData: {},
      });
      return;
    }

    // const orderData = {
    //   postcode: domicilio.address.postcode,
    //   street_name: streetName,
    //   number_address: numberAddress,
    //   state: state.value,
    //   city: city.value,
    // };
    //
    // try {
    //   const productosResponse = await addToCheckout(orderData);
    //   if (productosResponse.status === 200) {
    //     Swal.fire({
    //       title: "¡Éxito!",
    //       text: "Producto basico añadido exitosamente",
    //       icon: "success",
    //     }).then(() => {
    //       navegate("/consumidor/carrito");
    //     });
    //   } else {
    //     Swal.fire({
    //       title: "¡Error!",
    //       text: productosResponse.message,
    //       icon: "error",
    //     }).then(() => {});
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const handleDomicilioChange = (value) => {
    const { address } = value;
    setDomicilio(value);

    if (address) {
      const { state, county, city } = address;
      if (state)
        setState({ value: state.toUpperCase(), label: state.toUpperCase() });
      if (county)
        setCity({ value: county.toUpperCase(), label: county.toUpperCase() });
      if (city)
        setCity({ value: city.toUpperCase(), label: city.toUpperCase() });
    }
  };

  const handleStateChange = (selectedOption) => {
    setState(selectedOption);
  };
  const handleCityChange = (selectedOption) => {
    setCity(selectedOption);
  };

  return (
    <div>
      <Typography variant="h2">Checkout</Typography>
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="domicilio">
              <Form.Label>Código postal</Form.Label>
              <ComboDomicilio
                required={true}
                onChange={handleDomicilioChange}
              />
              {validated && !domicilio ? (
                <Typography variant="body2" style={{ color: "red" }}>
                  Seleccione una opción.
                </Typography>
              ) : (
                <></>
              )}
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="streetName">
              <Form.Label>Nombre de la calle</Form.Label>
              <Form.Control
                required={true}
                type="text"
                value={streetName}
                onChange={(event) => setStreetName(event.target.value)}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="numberAddress">
              <Form.Label>Numero</Form.Label>
              <Form.Control
                required={true}
                type="text"
                value={numberAddress}
                onChange={(event) => setNumberAddress(event.target.value)}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="state">
              <Form.Label>Estado</Form.Label>
              <Select
                options={estados}
                placeholder="Buscar..."
                value={state}
                onChange={handleStateChange}
                isInvalid={validated && !state}
              />
              {validated && !state ? (
                <Typography variant="body2" style={{ color: "red" }}>
                  Seleccione una opción.
                </Typography>
              ) : (
                <></>
              )}
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="city">
              <Form.Label>Ciudad</Form.Label>
              <Select
                options={cityOptions}
                placeholder="Buscar..."
                value={city}
                onChange={handleCityChange}
                isInvalid={validated && !city}
              />
              {validated && !city ? (
                <Typography variant="body2" style={{ color: "red" }}>
                  Seleccione una opción.
                </Typography>
              ) : (
                <></>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Col xs={12}>
          <Button variant="warning" type="submit">
            Validar información
          </Button>

          {/* <PayPalScriptProvider
            options={{
              "client-id":
                "AforG8kADxq4xKJuuFWgAIvM-sWzMq_Z9K81_698LpxWdDLYEx-F6Zw-9C7DX0wBPM2fqM9NCeHHgBVd",
              currency: "MXN",
            }}
          >
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: calculateTotal,
                      },
                    },
                  ],
                });
              }}
              onApprove={(data, actions) => {
                return actions.order.capture().then(function (details) {
                  console.log(details);
                  alert(
                    "Transaction completed by " + details.payer.name.given_name
                  );
                });
              }}
            />
          </PayPalScriptProvider> */}
        </Col>
      </Form>
    </div>
  );
}

export default CheckoutForm;
