import React, { useEffect, useState } from 'react'
import { Wrapper } from './PedidoIndividualItemStyle'
import { useParams,useNavigate } from 'react-router-dom';
import { CardActionArea, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { FormatPrice } from '../../../../componentes/FormatPrice/FormatPrice'

export const PedidoIndividualItem = (product) => {
    const navigate = useNavigate();
    const verProducto = () => {
        navigate("/producto/" + product.product.product_id);
    };

    console.log(product);

    return (
        <Wrapper>
            <img
                src={
                    product.product.imgPath
                        ? product.product.imgPath
                        : "https://dummyimage.com/250x250/fff/aaa"
                }
                alt={product.product.name}
            />
            <div>
                <CardActionArea onClick={verProducto}>
                    <Grid container alignItems="center">
                        <Grid item xs={6}>
                            <Typography variant="h4" sx={{ margin: "10px" }}>
                                {product.product.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h6" align="right">
                                {((product.product.discount * 100) / product.product.unit_price).toFixed(0) >
                                    0 ? (
                                    <>
                                        <span style={{ textDecoration: "line-through" }}>
                                            MXN $ <FormatPrice price={(product.product.quantity * product.product.unit_price)}/>
                                        </span>{" "}
                                        <span style={{ color: "#ff4747" }}>
                                            {((product.product.discount * 100) / product.product.unit_price).toFixed(
                                                0
                                            )}
                                            {"% Descuento"}
                                        </span>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="h4" align="right">
                        MXN $ <FormatPrice price={(
                                product.product.quantity *
                                (product.product.unit_price - product.product.discount)
                            )}/>
                    </Typography>
                </CardActionArea>

                <CardContent>
                    <Typography variant="h3">{product.product.description}</Typography>
                    <Typography variant="h5">{product.product.colors}</Typography>
                    <div>
                        <Typography variant="h6">
                            Precio por unidad: MXN $ <FormatPrice price={product.product.unit_price}/>
                        </Typography>
                        <Typography variant="h6">
                            Unidades: {product.product.quantity}
                        </Typography>
                    </div>
                </CardContent>
            </div>
        </Wrapper>
    );
};