import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useState } from "react";
import { ventasVendedorDashboard as ventasDashboard } from "../../../services/dashboard";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import YearCombo from "./YearCombo";
import { Button, Form } from "react-bootstrap";
import { reporteDashboardVentas } from "../../../services/documentos";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartComponent = () => {
  const [salesData, setSalesData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [reportLoading, setReportLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // console.log(new Date().getFullYear());
  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value, 10));
  };
  // Function to fetch API data based on selected date range
  const fetchData = async () => {
    try {
      const response = await ventasDashboard(startDate, endDate);
      setSalesData(response);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const chartData = {
    labels: salesData.map((entry) => entry.month_year), // Use the 'month_year' property from the fetched data
    fill: true,
    datasets: [
      {
        label: "Unidades",
        data: salesData.map((entry) => entry.sales),
        backgroundColor: "rgba(75, 192, 192, 0.7)",
      },
    ],
  };
  const chartDataGanancias = {
    labels: salesData.map((entry) => entry.month_year), // Use the 'month_year' property from the fetched data
    fill: true,
    datasets: [
      {
        label: "MEX$",
        data: salesData.map((entry) => entry.ganancia),
        backgroundColor: "rgba(75, 192, 192, 0.7)",
      },
    ],
  };

  const handleClick = async () => {
    // setReportLoading(true);
    const response = await reporteDashboardVentas(startDate, endDate);
    const fileUrl = `${process.env.REACT_APP_BASE_URL}/documents/${response.filename}`;
    console.log(fileUrl);
    window.open(fileUrl, "_blank");
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "black", // Label color
        },
      },
      title: {
        display: true,
        text: "Unidades vendidas",
        color: "black",
      },
      scales: {
        x: {
          type: "category",
          ticks: {
            color: "black", // X-axis tick color
          },
          grid: {
            display: true,
          },
        },
        y: {
          grid: {
            color: "black",
            display: true,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      },
    },
  };

  const chartOptionsGanancias = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "black", // Label color
        },
      },
      title: {
        display: true,
        text: "Ganancias calculadas",
        color: "black",
      },
      scales: {
        x: {
          type: "category",
          ticks: {
            color: "black", // X-axis tick color
          },
          grid: {
            display: true,
          },
        },
        y: {
          grid: {
            display: true,
          },
          ticks: {
            color: "black",
            beginAtZero: true,
          },
        },
      },
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={5}>
        <Form.Group className="mb-3" controlId="startDate">
          <Form.Control
            type="date"
            value={startDate}
            onChange={handleChangeStartDate}
          />
        </Form.Group>
        <br></br>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Form.Group className="mb-3" controlId="endDate">
          <Form.Control
            type="date"
            value={endDate}
            onChange={handleChangeEndDate}
          />
        </Form.Group>
        <br></br>
      </Grid>
      <Grid item xs={12} sm={2}>
        <div className="d-grid gap-2">
          <Button disabled={reportLoading} onClick={handleClick}>
            {reportLoading ? "Cargando reporte" : "Reporte"}
          </Button>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}
      >
        <Bar data={chartData} options={chartOptions} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}
      >
        <Bar data={chartDataGanancias} options={chartOptionsGanancias} />
      </Grid>
    </Grid>
  );
};

export default ChartComponent;
