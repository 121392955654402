import React from "react";
import AsyncSelect from "react-select/async";
import { getDomicilio } from "../../services/address";

class ComboDomicilio extends React.Component {
  timeoutId = null;
  controller = new AbortController();

  loadOptions = async (inputValue) => {
    try {
      this.controller.abort(); // Abort any ongoing request
    } catch (error) {
      // Ignore error if there is no ongoing request
    }

    this.controller = new AbortController(); // Create a new AbortController

    const signal = this.controller.signal; // Get the signal from the AbortController

    return new Promise((resolve) => {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      this.timeoutId = setTimeout(async () => {
        try {
          const response = await getDomicilio(
            { value: inputValue },
            { signal }
          ); // Make the API call with the signal
          const addressOptions = response.map((address) => ({
            value: address.place_id,
            label: address.display_name,
            address,
          }));
          resolve(addressOptions);
        } catch (error) {
          if (error.name === "AbortError") {
            console.log("Request aborted");
          } else {
            console.error(error);
          }
          resolve([]);
        }
      }, 500);
    });
  };

  handleChange = (selectedOption) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(selectedOption.address);
    }
  };

  componentWillUnmount() {
    this.controller.abort(); // Abort any ongoing request when the component is unmounted
    clearTimeout(this.timeoutId); // Clear the timeout when the component is unmounted
  }

  render() {
    return (
      <AsyncSelect
        defaultOptions
        cacheOptions
        placeholder="Buscar..."
        loadOptions={this.loadOptions}
        onChange={this.handleChange}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            text: "black", // Change the font color here
          },
        })}
      />
    );
  }
}

export default ComboDomicilio;
