import { io } from "socket.io-client";
import { API_URL_PATH } from "./services/config";

const createSocket = () => {
  const socket = io(API_URL_PATH, {
    reconnectionDelayMax: 10000,
    auth: {
      userId: localStorage.getItem("userid"),
      token: localStorage.getItem("accessToken"),
    },
  });

  return socket;
};

const socket = createSocket();

export const getSocket = () => {
  if (!socket.connected) {
    socket.connect();
  }
  return socket;
};
