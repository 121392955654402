import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import ImageIcon from "@mui/icons-material/Image";

const ChatManager = ({ conversations, cambiarChat }) => {
  return (
    <List
      sx={{
        flex: 1,
        maxWidth: 360,
        bgcolor: "background.paper",
      }}
    >
      {conversations.map((item) => (
        <React.Fragment key={item.conversation_partner_id}>
          <ListItem
            button
            onClick={() => {
              cambiarChat(
                item.conversation_partner_id,
                item.user1_id == localStorage.getItem("userid")
                  ? item.user2_id
                  : item.user1_id
              );
            }}
          >
            <ListItemAvatar>
              <Avatar>
                <ImageIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.conversation_partner_id}
              secondary={
                item.user1_id == localStorage.getItem("userid")
                  ? item.user2_nombre
                  : item.user1_nombre
              }
            />
          </ListItem>
          <Divider component="li" />
        </React.Fragment>
      ))}
    </List>
  );
};

export default ChatManager;
