import * as React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Container } from "@mui/system";
import theme from "./headerTheme";
import Logo from "./logo/logo";
import { Badge, Typography } from "@mui/material";
import { logOut } from "../../services/LoginService";
import { messagesAlert } from "../../services/chat";
import { useContext, useState, useEffect } from "react";
import NotificationCountContext from "../../hooks/NotificationCountContext";
import ChatIcon from "@mui/icons-material/Chat";
import useSocketSetup from "../../useSocketSetup";
import { getSocket } from "../../socket";
import { AccountContext } from "../../hooks/AccountContext";

export default function PrimarySearchAppBar() {
  useSocketSetup();
  const socket = getSocket();

  const [loginTab, setLoginTab] = useState(<></>);
  const [tabs, setTabs] = useState([
    // { nombre: "Hogar", ruta: "/" },
    { nombre: "Sobre nosotros", ruta: "/nosotros" },
    { nombre: "Productos", ruta: "/productos" },
    { nombre: "Contáctanos", ruta: "/contactanos" },
    // { nombre: "Inicia sesion", ruta: "/login" },
  ]);

  const { user, updateUser } = useContext(AccountContext);
  const [auth, setAuth] = useState(true);
  const [mensageNoti, setMensageNoti] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  const {
    notificationCount,
    updateNotificationCount,
    // mensageNoti,
    // setMensageNoti,
  } = useContext(NotificationCountContext);

  const handleNewAlert = async () => {
    try {
      const response = await messagesAlert();
      console.log(response);
      setMensageNoti(response[0].conversation_count);
    } catch (error) {
      // Handle the error here, such as logging or displaying an error message
      console.error("Error fetching messages alert:", error);
    }
  };

  useEffect(() => {
    let tipo;
    try {
      tipo = user.tipo;
    } catch {
      tipo = undefined;
    }
    const renderLoginTab = (type) => {
      const nombre = user.nombre;
      const menuItems = [
        {
          text: `Hola ${nombre}!`,
          action: () => navigate(`/${type}`),
        },
        {
          text: `Mis pedidos`,
          action: () => navigate(`consumidor/pedidos`),
        },
        {
          text: "Cerrar sesión",
          action: () => {
            logOut();
            handleClose();
            // setUser(); // Clear the user data from the context or state
            updateUser(null);
            socket.disconnect(); // Close the socket connection
            navigate("/");
          },
        },
      ];

      const menuCarritoItems = [
        {
          text: `Hola ${nombre}!`,
          action: () => navigate(`consumidor/carrito`),
        },
      ];

      return (
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <IconButton
            size="large"
            color="inherit"
            onClick={handleCarritoMenuOpen}
          >
            <Badge badgeContent={notificationCount} color="error">
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
          <IconButton size="large" color="inherit" onClick={handleChatMenuOpen}>
            <Badge badgeContent={mensageNoti} color="error">
              <ChatIcon />
            </Badge>
          </IconButton>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleUserMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {menuItems.map((item) => (
              <MenuItem key={item.text} onClick={item.action}>
                {item.text}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      );
    };

    setLoginTab(<></>);

    if (!user) {
      setTabs([
        { nombre: "Sobre nosotros", ruta: "/nosotros" },
      { nombre: "Productos", ruta: "/productos" },
      { nombre: "Contáctanos", ruta: "/contactanos" },
        { nombre: "Inicia sesión", ruta: "/login" },
      ]);
    } else {
      setTabs([
        { nombre: "Sobre nosotros", ruta: "/nosotros" },
        { nombre: "Productos", ruta: "/productos" },
        { nombre: "Contáctanos", ruta: "/contactanos" },
      ]);
      
      setLoginTab(tipo ? renderLoginTab(tipo) : null);
      updateNotificationCount();
    }
  }, [user, anchorEl, notificationCount, mensageNoti, socket]);

  useEffect(() => {
    socket.on("handleNewAlert", handleNewAlert);
    return () => {
      // Cleanup socket event listeners
      socket.off("handleNewAlert", handleNewAlert);
    };
  }, [mensageNoti]);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCarritoMenuOpen = () => {
    navigate(`/consumidor/carrito`);
  };

  const handleChatMenuOpen = () => {
    navigate(`/consumidor/chat`);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={theme}>
        <AppBar position="static">
          <Container>
            <Toolbar>
              <NavLink
                to={"/"}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Logo />
              </NavLink>

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {tabs.map((tab) => (
                  <NavLink
                    key={tab.ruta}
                    to={tab.ruta}
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <MenuItem key={"header-" + tab.nombre}>
                      <Typography textAlign="center">{tab.nombre}</Typography>
                    </MenuItem>
                  </NavLink>
                ))}
              </Box>
              {loginTab}
            </Toolbar>
          </Container>
        </AppBar>
      </ThemeProvider>
    </Box>
  );
}
