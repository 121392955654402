import { useMemo, useState } from "react";
import UserIndex from "./pages/MainPage/UserIndex";
import { AccountProvider } from "./hooks/AccountContext";

export default function App() {

  return (
    <AccountProvider>
      <UserIndex/> {/* <App/> */}
    </AccountProvider>
  );
}
