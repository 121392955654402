import { useEffect, useState } from "react";
import CartItem from "../cartItem/cartItem";

import { Wrapper } from "./cartStyles";
import { Typography } from "@mui/material";

const Cart = ({ cartItems, addToCart, removeFromCart }) => {
  return (
    <Wrapper>
      {cartItems.map((item) => (
        <CartItem
          key={item.id}
          product={item}
          addToCart={(item) => addToCart(item)}
          removeFromCart={(item) => removeFromCart(item)}
        />
      ))}
    </Wrapper>
  );
};

export default Cart;
