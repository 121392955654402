import styled from "styled-components";

export const Wrapper = styled.div`
  *{
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .product-display {
    margin: 20px 0;
  }

  .productos{
    display:flex;
  }
`;