import { ColorModeContext, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../../ventas/components/dashboard";

import Sidebar from "../../ventas/components/global/Sizebar";
import FormProductos from "../../ventas/components/productos-basic/productos-form";
import GridProductos from "../../ventas/components/productos-basic/productos-grid";
//productos
//import FormProductosAdmin from "../../ventas/components/productos/form-productos";
// import GridProductosAdmin from "../../admin/components/productos/grid-productos";
// import UpdateProductosAdmin from "../../admin/components/productos/update-productos";
import FormProductosAdmin from "../../ventas/components/productos/form-productos";
import GridProductosAdmin from "../../ventas/components/productos/grid-productos";
import UpdateProductosAdmin from "../../ventas/components/productos/update-productos";

import GridPedidos from "../../ventas/components/pedidos/gridPedidos";
import Pedidos from "../../ventas/components/pedidos/pedidosAdmin";

import { ProSidebarProvider } from "react-pro-sidebar";
import { useState } from "react";
import "../../ventas/index.css";

const VendedorPage = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app" style={{ minHeight: "100vh", display: "flex" }}>
          <ProSidebarProvider>
            <Sidebar isSidebar={isSidebar} />
          </ProSidebarProvider>
          <main className="content" style={{ flexGrow: 1 }}>
            <br />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              {/* <Route path="/addProductos" element={<FormProductos />} />
              <Route path="/gridProductos" element={<GridProductos />} /> */}
              {/* Productos  */}
              <Route
                path="/addProductosVendedor"
                element={<FormProductosAdmin />}
              />
              <Route
                path="/gridProductosVendedor"
                element={<GridProductosAdmin />}
              />
              <Route
                path="/updateProductos/:id"
                element={<UpdateProductosAdmin />}
              />

              {/* Pedidos */}
              <Route path="/gridPedidos" element={<GridPedidos />} />
              <Route path="/pedidos/:id" element={<Pedidos />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default VendedorPage;
